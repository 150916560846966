import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  currentUserDataSelector,
  store,
  feesSavedDefault,
  employerNewDataSelector
} from '@plootus/common';
import axios from 'axios';
import moment from 'moment';

const selectStrategy = (data, strategy) => {
  switch (strategy) {
    case 0:
      return data[0]?.superconservative;
    case 1:
      return data[0]?.conservative;
    case 2:
      return data[0]?.moderate;
    case 3:
      return data[0]?.growth;
    case 4:
      return data[0]?.supergrowth;
    default:
      return null;
  }
};

export const useFeesSaved = (strategy) => {
  const token = useSelector(({ userReducer }) => userReducer?.token);
  const userData = currentUserDataSelector(store.getState());
  const [graphData, setGraphData] = useState({
    data: [],
    total: 0,
    flag: null,
    saved: 0
  });

  const [feesSavedData, setFeesSavedData] = useState([]);

  const employerEin = userData?.employerEin;
  const sponsor_name = userData?.sponsor_name;

  const { employer_selected, selected_ein } = useSelector(
    employerNewDataSelector
  );
  const createdAt = userData?.createdAt ? userData?.createdAt : moment.now();
  useEffect(() => {
    (async () => {
      try {
        if (token) {
          const response = await axios.get(
            `https://www.plootus.com/api/employee/getfeessaved?ein=${
              employerEin ? employerEin : selected_ein
            }`,
            {
              headers: {
                Authorization: token
              }
            }
          );
          setFeesSavedData(response.data?.fees);
        }
      } catch (err) {
        console.log(err);
      }
    })();
  }, [sponsor_name, employer_selected]);

  useEffect(() => {
    if ((employerEin || selected_ein) && feesSavedData.length) {
      // feesSavedData is used incase data is empty from backend
      const tempData = selectStrategy(
        feesSavedData,
        employerEin || selected_ein ? strategy : 3
      );
      let temp = [];
      let tot = 0;
      for (let i = 0; i < tempData?.length; ++i) {
        temp.push({
          x: parseInt(moment(userData.createdAt).format('YYYY')) + i,
          y: tempData[i]
        });
        tot += tempData[i];
      }
      setGraphData({
        data: [{ data: temp }],
        total: tot,
        flag: 1,
        saved: temp[temp.length - 1].y
        // saved: temp[temp.length - 1],
      });
    } else {
      let temp = [];
      for (
        let i = 0;
        i < feesSavedDefault[strategy ? strategy : 0]?.data?.length;
        i++
      ) {
        temp.push({
          x: parseInt(moment(createdAt).format('YYYY')) + i,
          y: feesSavedDefault[strategy ? strategy : 0]?.data[i]
        });
      }
      setGraphData({
        data: [{ data: temp }],
        total: feesSavedDefault[strategy ? strategy : 0]?.total,
        flag: 0,
        saved: temp[temp.length - 1].y
      });
    }
  }, [strategy, sponsor_name, employer_selected, feesSavedData]);
  return graphData;
};
