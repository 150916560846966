import React, { useState, useEffect } from 'react';
import styles from './Blogs.module.css';
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import Axios from 'axios';
import BlogCard from './BlogCard';
import useWindowDimensions from '../../../Reusables/VisualAndAccord/Hooks/useWindowDimensions';
import CircularProgress from '@material-ui/core/CircularProgress';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { BLUE_PLOOT } from '@plootus/common';

const theme = createMuiTheme({
  palette: {
    primary: { main: BLUE_PLOOT }, // Purple and green play nicely together.
  },
  typography: { useNextVariants: true },
});

const Blogs = () => {
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    async function f() {
      const result = await Axios.get(
        'https://www.plootus.com/blog/?rest_route=/wp/v2/posts&per_page=20'
      );
      setBlogs(result.data);
    }
    f();
  }, []);

  const { width } = useWindowDimensions();
  let cnt = 3;
  if (width <= 576) cnt = 1;
  else if (width <= 1024) cnt = 2;
  if (!blogs || blogs.length === 0) {
    return (
      <div className={styles.container}>
        <h1 className={styles.header}>Read Our Blogs</h1>
        <div className={styles.loader}>
          <MuiThemeProvider theme={theme}>
            <CircularProgress size={40} />
          </MuiThemeProvider>
        </div>
      </div>
    );
  }

  //console.log(blogs);

  return (
    <div className={styles.container}>
      <h1 className={styles.header}>Read Our Blogs</h1>
      <div className={styles.carDiv}>
        <CarouselProvider
          naturalSlideWidth={width < 800 ? 70 : 80}
          naturalSlideHeight={100}
          totalSlides={blogs.length}
          visibleSlides={cnt}
          isPlaying={true}
        >
          <Slider>
            {blogs?.map((blog, index) => (
              <Slide index={index} key={index}>
                <BlogCard blog={blog} />
              </Slide>
            ))}
          </Slider>
          <div className={styles.carBtns}>
            <ButtonBack className={styles.btns}>{`<`}</ButtonBack>
            <ButtonNext className={styles.btns}>{`>`}</ButtonNext>
          </div>
        </CarouselProvider>
      </div>
    </div>
  );
};

export default Blogs;
