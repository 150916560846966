// import React, { useEffect, useState, useContext } from 'react';
// import styles from './StaticEmployer.module.css';
// import EmployeeSvg from '../../Assets/employee.svg';
// import Fsvg from '../../Assets/fb.png';
// import SearchBox401k from '../../Utils/Search401kNew/SearchBox401kNew';
// import EmpCarousel from './EmpCarousel';
// import FeesGraph from '../Dashboard/Graphs/FeesGraph';

// import { useSelector } from 'react-redux';
// import {
//   usePickerModal,
//   strategy_constants,
//   employerNewDataSelector,
// } from '@plootus/common';
// import Picker from '../../Utils/Containers/Picker/Picker';
// import NewPie from '../../Utils/Charts/NewPie/NewPie';
// import FundsTable from '../Employer/FundsTable';
// import MessageBox from '../Employer/MessageBox/MessageBox';
// import MainSignupScreen from '../SignUp/MainScreen';
// import useWindowDimensions from '../../Reusables/VisualAndAccord/Hooks/useWindowDimensions';
// import useEmployerSearch from '../../Reusables/VisualAndAccord/Hooks/useEmployerSearch';

// import { Helmet } from 'react-helmet';
// import LoginSignupContext from '../../Reusables/VisualAndAccord/Context/LoginSignupContext';

// const StaticEmployer = () => {
//   const { width } = useWindowDimensions();

//   const props = useEmployerSearch();
//   const { funds } = useSelector(employerNewDataSelector);
//   const [strategy, setStrategy] = useState(0);
//   const [prevS, setPrevS] = useState(strategy);
//   const [loadingPie, setLoadingPie] = useState(false);
//   var empImages=[];
//   useEffect(() => {
//     setLoadingPie(true);
//     setTimeout(() => {
//       setPrevS(strategy);
//       setLoadingPie(false);
//     }, 1000);
//   }, [strategy]);

//   useEffect(()=>{

//     if(props.data?.length){

//       for(var i=0;i<props.data.length;i++){
//         empImages.push(`https://www.plootus.com/iconImages/${props.data[i].ein}.png`)
//       }
//     console.log(empImages)
//   }
//   },[props.data])

//   const { picker, negativeModal, setIndex } = usePickerModal(
//     (idx) => {
//       setStrategy(idx);
//     },
//     strategy,
//     true
//   );

//   /* For Modal */
//   const { signupModal, setSignupModal, setLoginModal } = useContext(
//     LoginSignupContext
//   );

//   const loginopenModal = () => {
//     setLoginModal(true);
//   };

//   const openModal = () => {
//     setSignupModal(true);
//   };

//   const closeModal = () => {
//     setSignupModal(false);
//   };

//   return (
//     <>
//  {props.employer_selected !== '' &&
//         !props.data?.length &&
//         funds.length ? (
//           <div>
//           <div className={styles.row123}>
//    <img className={styles.empImg1} src={Fsvg} alt="" ></img>
//    <div className={styles.title1}>
//        <div className={styles.mainText1}>Facebook, Inc</div>
//        <div className={styles.einText1}>(EIN-32423245)</div>
//    </div>
//    </div>
//    <div className={styles.row11}>
//    <div className={styles.column11}></div>
//    <div className={styles.column21}>
//    <h4 className={styles.stepper}>About the Plan</h4>
//    <br/>

//    <ul>
//        <li>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Corrupti aut hic mollitia magni cupiditate ab excepturi vitae quo facilis. </li>
//        <div style={{marginTop:10}}/>
//        <li>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Doloremque corrupti quas distinctio commodi laudantium! Eaque temporibus nesciunt aliquam doloremque rerum quam quo. Quisquam dolore quaerat commodi corrupti voluptas, illo dolorum.</li>
//        <div style={{marginTop:10}}/>
//        <li>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Corrupti aut hic mollitia magni cupiditate ab excepturi vitae quo facilis. </li>

//    </ul>
//    </div>
//    </div>
//    <div className={styles.row11}>

//              <div className={styles.column31}>
//              <div className={styles.feesGraph1}>
//                <FeesGraph coming={false} strategy={3} fromStatic={true} />
//              </div>
//              </div>
//    <div className={styles.column41}></div>
//    </div>
//    <div className={styles.row10}>
//            {/* {props.employer_selected !== '' &&
//            !props.data?.length &&
//            funds.length ? (
//              <> */}
//                <div className={styles.pickPie}>
//                  <div className={styles.pickerOuter}>
//                    <div className={styles.outerPicker}>
//                      <div className={styles.pickerHead}>Select Your Strategy</div>
//                      <div className={styles.pickerActual}>
//                        <Picker
//                          rows={strategy_constants}
//                          {...picker}
//                          handlePress={negativeModal}
//                          setIndex={setIndex}
//                          myStyle={
//                            width < 1030
//                              ? {
//                                  padding: '0px',
//                                  width: '200px',
//                                  marginLeft: width < 576 ? '20px' : '0px',
//                                  marginTop: '10px',
//                                  marginBottom: '20px',
//                                }
//                              : { padding: '0px' }
//                          }
//                          myStyle2={{
//                            margin: '0%',
//                            width:
//                              width < 1300 ? (width < 1024 ? '70%' : '40%') : '30%',
//                          }}
//                          myStyle3={{ width: width < 1300 ? '150%' : '100%' }}
//                          myStyle4={{ width: width < 1300 ? '150%' : '100%' }}
//                        />
//                      </div>
//                    </div>
//                    <div className={styles.empty1}></div>
//                  </div>
//                </div>
//                <div className={styles.tableOuter}>
//                  <div className={styles.tableInner}>
//                    <FundsTable strategy={strategy} />

//                  </div>
//                </div>
//              {/* </>
//            ) : null} */}
//          </div>
//          <div className={styles.empty1}></div>
//          <div className={styles.empty1}></div>
//          <div className={styles.empty1}></div>
//        </div>):(
//             <div className={funds.length ? styles.root : styles.invisibleRoot}>

//             <Helmet>
//               <meta charSet="utf-8" />
//               <title>401k IRA 403b 457 Financial Investment Advisors | Plootus</title>
//               <meta
//                 name="description"
//                 content="Professional Investment Advisors to give advice beyond traditional retirement. Analyze your 401k, IRA, 430b and 457 options. Simply add your employer to get your free financial advice.
//                 "
//               />
//               <meta
//                 name="keywords"
//                 content="401k plan, 403b plan, Retirement Planning, Financial Planning, 401k rollover, 401k early withdrawl, 457 plan, TSP plan,401k, 403b, early retirement, student loan
//                 "
//               />
//             </Helmet>

//             <div className={styles.row1}>
//               <div className={styles.search}>
//                 <div className={styles.searchParent}>
//                   Search Your Employer
//                   <SearchBox401k {...props} fromStatic={true} dontNull={true} />
//                 </div>
//                 <div className={styles.empty}>
//                   {props.employer_selected !== '' &&
//                   !props.data?.length &&
//                   !funds.length ? (
//                     <div
//                       className={styles.messageBox}
//                       style={{ marginRight: '20px' }}
//                     >
//                       <MessageBox />
//                     </div>
//                   ) : null}
//                 </div>
//               </div>
//               <div className={styles.image}>
//                 <img className={styles.innerImage} src={EmployeeSvg} alt="" />
//               </div>
//             </div>
//             {
//                   props.data?.length?(
//                    empImages.map((item, index) => {
//                       return (<>
//                   <div className={styles.row12}>
//       <img className={styles.empImg} src={{URL:item}} alt="" ></img>

//       </div>
//                   </>)})):null}

//             <div className={styles.row2}>
//               {props.employer_selected !== '' &&
//               !props.data?.length &&
//               funds.length ? (
//                 <>
//                   <div className={styles.pickPie}>
//                     <div className={styles.pickerOuter}>
//                       <div className={styles.outerPicker}>
//                         <div className={styles.pickerHead}>Select Your Strategy</div>
//                         <div className={styles.pickerActual}>
//                           <Picker
//                             rows={strategy_constants}
//                             {...picker}
//                             handlePress={negativeModal}
//                             setIndex={setIndex}
//                             myStyle={
//                               width < 1030
//                                 ? {
//                                     padding: '0px',
//                                     width: '200px',
//                                     marginLeft: width < 576 ? '20px' : '0px',
//                                     marginTop: '10px',
//                                     marginBottom: '20px',
//                                   }
//                                 : { padding: '0px' }
//                             }
//                             myStyle2={{
//                               margin: '0%',
//                               width:
//                                 width < 1300 ? (width < 1024 ? '70%' : '40%') : '30%',
//                             }}
//                             myStyle3={{ width: width < 1300 ? '150%' : '100%' }}
//                             myStyle4={{ width: width < 1300 ? '150%' : '100%' }}
//                           />
//                         </div>
//                       </div>
//                       <div className={styles.empty1}></div>
//                     </div>
//                   </div>
//                   <div className={styles.tableOuter}>
//                     <div className={styles.tableInner}>
//                       <FundsTable strategy={strategy} />
//                       <div className={styles.blur}>
//                         <div className={styles.btnOuter}>
//                           <MainSignupScreen
//                             modalIsOpen={signupModal}
//                             openModal={openModal}
//                             closeModal={closeModal}
//                             isInEmployeer={true}
//                             loginopenModal={loginopenModal}
//                           />
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </>
//               ) : null}
//             </div>
//             <div className={styles.empty}></div>
//             <div className={styles.adds}>
//               <EmpCarousel />
//             </div>
//           </div>

//           )}
//     </>

//   );
// };

// export default StaticEmployer;

import React, { useEffect, useState, useContext } from 'react';
import styles from './StaticEmployer.module.css';
import EmployeeSvg from '../../Assets/employee.svg';
import SearchBox401k from '../../Utils/Search401kNew/SearchBox401kNew';
import EmpCarousel from './EmpCarousel';
import Fsvg from '../../Assets/fb.png';
import { useSelector, useDispatch } from 'react-redux';
import {
  usePickerModal,
  strategy_constants,
  fetch_allocations,
  employerNewDataSelector
} from '@plootus/common';
import Picker from '../../Utils/Containers/Picker/Picker';
import NewPie from '../../Utils/Charts/NewPie/NewPie';
import FundsTable from '../Employer/FundsTable';
import MessageBox from '../Employer/MessageBox/MessageBox';
import MainSignupScreen from '../SignUp/MainScreen';
import useWindowDimensions from '../../Reusables/VisualAndAccord/Hooks/useWindowDimensions';
import useEmployerSearch from '../../Reusables/VisualAndAccord/Hooks/useEmployerSearch';

import { Helmet } from 'react-helmet';
import LoginSignupContext from '../../Reusables/VisualAndAccord/Context/LoginSignupContext';

const StaticEmployer = () => {
  const { width } = useWindowDimensions();

  const props = useEmployerSearch();
  const { funds } = useSelector(employerNewDataSelector);
  const [strategy, setStrategy] = useState(0);
  const [prevS, setPrevS] = useState(strategy);
  const [loadingPie, setLoadingPie] = useState(false);
  useEffect(() => {
    setLoadingPie(true);
    setTimeout(() => {
      setPrevS(strategy);
      setLoadingPie(false);
    }, 1000);
  }, [strategy]);
  const dispatch = useDispatch();
  const { picker, negativeModal, setIndex } = usePickerModal(
    (idx) => {
      setStrategy(idx);
    },
    strategy,
    true
  );

  /* For Modal */
  const { signupModal, setSignupModal, setLoginModal } = useContext(
    LoginSignupContext
  );

  const loginopenModal = () => {
    setLoginModal(true);
  };

  const openModal = () => {
    setSignupModal(true);
  };

  const closeModal = () => {
    setSignupModal(false);
  };

  const empLogos = [
    {
      amazon: require('../../Assets/employee-logos/amazon.png'),
      ein: '911986545',
      name: 'AMAZON.COM SERVICES,INC.'
    },
    {
      amazon: require('../../Assets/employee-logos/ge.png'),
      ein: '140689340',
      name: 'GENERAL ELECTRIC COMPANY'
    },
    {
      amazon: require('../../Assets/employee-logos/fb.png'),
      ein: '201665019',
      name: 'FACEBOOK, INC.'
    },
    {
      amazon: require('../../Assets/employee-logos/testla.png'),
      ein: '140689340',
      name: 'GENERAL ELECTRIC COMPANY'
    },
    {
      amazon: require('../../Assets/employee-logos/apple.png'),
      ein: '942404110',
      name: 'APPLE INC.'
    },
    {
      amazon: require('../../Assets/employee-logos/star.png'),
      ein: '911325671',
      name: 'STARBUCKS CORPORATION'
    },
    {
      amazon: require('../../Assets/employee-logos/nissan.png'),
      ein: '140689340',
      name: 'GENERAL ELECTRIC COMPANY'
    },
    {
      amazon: require('../../Assets/employee-logos/newyork.png'),
      ein: '135562308',
      name: 'NEW YORK UNIVERSITY'
    },
    {
      amazon: require('../../Assets/employee-logos/fairuni.png'),
      ein: '60646623',
      name: 'FAIRFIELD UNIVERSITY'
    },
    {
      amazon: require('../../Assets/employee-logos/alabama.png'),
      ein: '140689340',
      name: 'GENERAL ELECTRIC COMPANY'
    },
    {
      amazon: require('../../Assets/employee-logos/sacri.png'),
      ein: '140689340',
      name: 'GENERAL ELECTRIC COMPANY'
    },
    {
      amazon: require('../../Assets/employee-logos/michan.png'),
      ein: '140689340',
      name: 'GENERAL ELECTRIC COMPANY'
    }
  ];

  return (
    <div className={funds.length ? styles.root : styles.invisibleRoot}>
      <Helmet>
        <meta charSet='utf-8' />
        <title>401k IRA 403b 457 Financial Investment Advisors | Plootus</title>
        <meta
          name='description'
          content='Professional Investment Advisors to give advice beyond traditional retirement. Analyze your 401k, IRA, 430b and 457 options. Simply add your employer to get your free financial advice.
          '
        />
        <meta
          name='keywords'
          content='401k plan, 403b plan, Retirement Planning, Financial Planning, 401k rollover, 401k early withdrawl, 457 plan, TSP plan,401k, 403b, early retirement, student loan
          '
        />
      </Helmet>

      <div className={styles.row1}>
        <div className={styles.search}>
          <div className={styles.searchParent}>
            <SearchBox401k {...props} fromStatic={true} dontNull={true} />
          </div>
          <div className={styles.empty1}>
            {props.employer_selected !== '' &&
            !props.data?.length &&
            !funds.length ? (
              <div
                className={styles.messageBox}
                style={
                  width > 630
                    ? { marginRight: '20px', marginTop: '100px' }
                    : { marginTop: '200px' }
                }
              >
                <MessageBox />
              </div>
            ) : null}
          </div>
        </div>

        <div className={styles.image}>
          <img className={styles.innerImage} src={EmployeeSvg} alt='' />
        </div>
      </div>

      {props.employer_selected !== '' &&
      !props.data?.length &&
      funds.length ? null : (
        <>
          <div className={styles.row12}>
            <h5 className={styles.spe}>Popular 401k and 403b Searches</h5>
            {empLogos.map((item, key) => {
              return (
                <>
                  <img
                    onClick={async () => {
                      await dispatch(
                        fetch_allocations(
                          {
                            ein: item.ein,
                            name: item.name.split(' (EIN')[0]
                          },
                          true
                        )
                      );
                    }}
                    className={styles.empImg}
                    src={item.amazon}
                    alt=''
                  ></img>
                </>
              );
            })}
          </div>
        </>
      )}

      <div className={styles.row2}>
        {props.employer_selected !== '' &&
        !props.data?.length &&
        funds.length ? (
          <>
            <div className={styles.pickPie}>
              <div className={styles.pickerOuter}>
                <div className={styles.outerPicker}>
                  <div className={styles.pickerHead}>Select Your Strategy</div>
                  <div className={styles.pickerActual}>
                    <Picker
                      rows={strategy_constants}
                      {...picker}
                      handlePress={negativeModal}
                      setIndex={setIndex}
                      myStyle={
                        width < 1030
                          ? {
                              padding: '0px',
                              width: '200px',
                              marginLeft: width < 576 ? '20px' : '0px',
                              marginTop: '10px',
                              marginBottom: '20px'
                            }
                          : { padding: '0px' }
                      }
                      myStyle2={{
                        margin: '0%',
                        width:
                          width < 1300 ? (width < 1024 ? '70%' : '40%') : '30%'
                      }}
                      myStyle3={{ width: width < 1300 ? '150%' : '100%' }}
                      myStyle4={{ width: width < 1300 ? '150%' : '100%' }}
                    />
                  </div>
                </div>
                <div className={styles.empty1}></div>
              </div>
            </div>
            <div className={styles.tableOuter}>
              <div className={styles.tableInner}>
                <FundsTable strategy={strategy} />
                <div className={styles.blur}>
                  <div className={styles.btnOuter}>
                    <MainSignupScreen
                      modalIsOpen={signupModal}
                      openModal={openModal}
                      closeModal={closeModal}
                      isInEmployeer={true}
                      loginopenModal={loginopenModal}
                    />
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : null}
      </div>
      <div
        className={styles.empty}
        style={
          props.employer_selected !== '' && !props.data?.length && funds.length
            ? { marginTop: width < 576 ? '50px' : '40px' }
            : { marginTop: width < 576 ? '50px' : '440px' }
        }
      ></div>
      <div className={styles.adds}>
        <EmpCarousel />
      </div>
    </div>
  );
};

export default StaticEmployer;
