"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.resetAlert = exports.sendAlert = exports.globalAction = void 0;
var types_1 = require("./types");
exports.globalAction = function (payload) { return ({
    type: types_1.GENERAL_GLOBAL_REDUCER,
    payload: payload,
}); };
exports.sendAlert = function (payload) { return ({
    type: types_1.SEND_ALERT,
    payload: payload,
}); };
exports.resetAlert = function () { return ({ type: types_1.RESET_ALERT }); };
