"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SendAlert = void 0;
var react_1 = __importStar(require("react"));
var react_redux_1 = require("react-redux");
var useDidUpdate_1 = require("../hooks/useDidUpdate");
var types_1 = require("../redux/global/types");
var styles_1 = require("../styles");
var SendAlertComp = function (_a) {
    var alert = _a.alert, render = _a.render, dispatch = _a.dispatch;
    var _b = react_1.useState(false), diagVisible = _b[0], setDiagVisible = _b[1];
    var buttonLeftPress = alert.buttonLeftPress, buttonRightPress = alert.buttonRightPress, buttonLeftText = alert.buttonLeftText, buttonRightText = alert.buttonRightText, rest = __rest(alert, ["buttonLeftPress", "buttonRightPress", "buttonLeftText", "buttonRightText"]);
    useDidUpdate_1.useDidUpdate(function () {
        if (alert.title) {
            setDiagVisible(true);
        }
    }, [alert]);
    var hideAlert = function () {
        dispatch({ type: types_1.RESET_ALERT });
        setDiagVisible(false);
    };
    return (react_1.default.createElement(react_1.default.Fragment, null, render(__assign({ textStyle: { textAlign: 'center' }, visible: diagVisible, setVisible: setDiagVisible, buttonLeftText: buttonLeftText, buttLeftStyle: {
            backgroundColor: styles_1.DISABLE_GREY,
        }, buttonLeftPress: function () {
            buttonLeftPress === null || buttonLeftPress === void 0 ? void 0 : buttonLeftPress();
            hideAlert();
        }, buttonRightPress: function () {
            buttonRightPress === null || buttonRightPress === void 0 ? void 0 : buttonRightPress();
            hideAlert();
        }, buttonRightText: buttonRightText || 'Ok' }, rest))));
};
exports.SendAlert = react_redux_1.connect(function (_a) {
    var globalReducer = _a.globalReducer;
    return ({
        alert: globalReducer.alert,
    });
})(SendAlertComp);
