import React from "react";
import styles from "./CalculatorStatic.module.css";
import EditHome from "../HomePage/EditAssumptions/EditAssumptions";

const CalculatorStatic = () => {
  return (
    <div className={styles.main}>
      <div className={styles.mytestHeader}>401k Calculator</div>
      <div className={styles.editA}>
        <EditHome />
      </div>
    </div>
  );
};

export default CalculatorStatic;
