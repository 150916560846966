import CircularProgress from "@material-ui/core/CircularProgress";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import {
  BLUE_PLOOT,
  CHANGE_CLIENT,
  CHANGE_TOKEN_STATE,
  GENERAL_GRAPH,
  GENERAL_USER_REDUCER,
  getEditAssumptionsInitialState,
  getUser,
  get_clients,
  logout_user,
  SEND_ALERT,
} from "@plootus/common";
import React, { useEffect, useState } from "react";
import Avatar from "react-avatar";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import useEmployerSearch from "../../Reusables/VisualAndAccord/Hooks/useEmployerSearch";
import SideNavbar from "../../Utils/Containers/Sidebar/SideNavbar";
import SearchBox401k from "../../Utils/Search401kNew/SearchBox401kNew";
import UserPicker from "../../Utils/UserPicker/UserPicker";
import Error404 from "../404/Error404";
import AdvisorOrClient from "../Advisor/AdvisorOrClient";
import AssetCatData from "../AssetsScreen/AssetCatData";
import Dashboard from "../Dashboard/Dashboard";
import Employer from "../Employer/Employer";
import IncomeExpTab from "../IncomeExpenseScreen/IncomeExpTab";
import Liabilities from "../LiabilitiesScreen/LiabilitiesScreen";
import ManageAccounts from "../LinkedAccounts/ManageAccounts";
import NetWorth from "../NetWorth/NetWorthData";
import Privacy from "../Privacy/Privacy";
import TermsAndConditionAuth from "../TermsandConditionAuth/TermsandConditionAuth";
import Adash from "./Adash";
import styles from "./AuthPages.module.css";
import Faqs from "./faq";
import { useRef } from "react";
import FooterAuth from "../../Utils/Containers/FooterAuth/FooterAuth";
import Calculator from "../401kCalculator/Calculator";
import DobModal from "../DOBModal/DobModal";

const theme = createMuiTheme({
  palette: {
    primary: { main: BLUE_PLOOT }, // Purple and green play nicely together.
  },
  typography: { useNextVariants: true },
});

function AuthPages() {
  const token = localStorage.getItem("jwt_token");
  const dispatch = useDispatch();
  const [showDobModal, setShowDobModal] = useState(false);
  const [drop, setDrop] = useState(false);
  const [loading, setloading] = useState(true);
  const user = useSelector((state) => state.userReducer);

  const [expire, setExpire] = useState(false);
  const props = useEmployerSearch();

  const myL = useRef("/");

  myL.current = useLocation().pathname;

  useEffect(() => {
    async function f() {
      setloading(true);
      let userDataTemp;
      if (token) {
        dispatch({ type: CHANGE_TOKEN_STATE, payload: { token } });
        // Get user data and store to reducer
        userDataTemp = await dispatch(getUser());
        if (userDataTemp?.status === 401) {
          // Remove session token

          dispatch({
            type: SEND_ALERT,
            payload: {
              title: "Expiration",
              textContent: "Session Expired",
            },
          });
          dispatch({
            type: GENERAL_USER_REDUCER,
            payload: { session_expired: false },
          });

          await localStorage.removeItem("jwt_token");
          dispatch(logout_user());

          setExpire(true);
          // Navigate to onboard
          return;
        }
        console.log("user", user);
      }

      if (userDataTemp?.userType === "advisor") {
        const temp = await dispatch(get_clients());
        if (!temp.length) {
          localStorage.removeItem("jwt_token");
          dispatch(logout_user());

          dispatch({
            type: SEND_ALERT,
            payload: {
              title: "Alert",
              textContent: "Please contact your client.",
            },
          });

          return <Redirect to="/" />;
        } else {
          const index = parseInt(await localStorage.getItem("selectedClient"))
            ? parseInt(await localStorage.getItem("selectedClient"))
            : 0;
          await dispatch({
            type: CHANGE_CLIENT,
            payload: index,
          });

          await dispatch({
            type: GENERAL_GRAPH,
            payload: await getEditAssumptionsInitialState(token, temp[index]),
          });
          setloading(false);
        }
      } else {
        if (userDataTemp?.dob === null) {
          setShowDobModal(true);
        }
        await dispatch({
          type: GENERAL_GRAPH,
          payload: await getEditAssumptionsInitialState(token, userDataTemp),
        });
        setloading(false);
      }
    }

    f();
  }, [
    user.advisor.selectedClient,
    user.token,
    localStorage.getItem("jwt_token"),
  ]);

  if (expire) return <Redirect to="/" />;

  if (loading) {
    return (
      <div
        style={{
          width: "99vw",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <MuiThemeProvider theme={theme}>
          <CircularProgress size={50} />
        </MuiThemeProvider>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <DobModal showDobModal={showDobModal} setShowDobModal={setShowDobModal} />
      <div className={styles.sidebar}>
        <SideNavbar />
      </div>
      <div className={styles.searchDiv}>
        <div className={styles.searchBarContainer}>
          {user.userData.employerEin === null &&
          myL.current === "/auth/dashboard" ? null : (
            <SearchBox401k {...props} />
          )}
        </div>
      </div>
      <div className={styles.userName} onClick={() => setDrop(true)}>
        {user ? (
          <>
            <div className={styles.avatar}>
              <Avatar
                name={`${user.userData?.firstName} ${user.userData?.lastName}`}
                size="40px"
                round="50%"
                //style={{ width: "90%", height: "90%", borderRadius: "50%" }}
              />
            </div>
            <div className={styles.userNameText}>
              <UserPicker
                setDrop={setDrop}
                username={`${user.userData?.firstName} ${user.userData?.lastName}`}
                dispatch={dispatch}
              />
            </div>
          </>
        ) : null}
      </div>

      <div className={styles.main_content}>
        <Switch>
          <Route exact path="/auth/401k" component={Employer} />

          {user?.userData?.userType === "advisor" ? (
            <Route exact path="/auth/adash" component={Adash} />
          ) : null}

          <Route exact path="/auth/dashboard" component={Dashboard} />

          <Route exact path="/auth/incomexp" component={IncomeExpTab} />
          <Route exact path="/auth/liabilities" component={Liabilities} />
          <Route exact path="/auth/assets" component={AssetCatData} />
          <Route exact path="/auth/networth" component={NetWorth} />

          <Route exact path="/auth/advisors" component={AdvisorOrClient} />
          <Route exact path="/auth/faq" component={Faqs} />
          <Route exact path="/auth/linkaccounts" component={ManageAccounts} />
          <Route exact path="/auth/401kcalculator" component={Calculator} />
          <Route
            exact
            path="/auth/termsandcondition"
            component={TermsAndConditionAuth}
          />
          <Route exact path="/auth/privacypolicy" component={Privacy} />
          <Route path="/auth" component={Error404} />
        </Switch>
      </div>
      <div className={styles.footer}>
        <FooterAuth />
      </div>
    </div>
  );
}

export default AuthPages;
