import React from 'react';
import styles from './Blogs.module.css';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const BlogCard = ({ blog }) => {
  var mydate = new Date(blog.date);
  var month = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ][mydate.getMonth()];
  var str = mydate.getDate() + ' ' + month + ' ' + mydate.getFullYear();

  var htmlString = blog.content.rendered;

  var stripedHtml = htmlString.replace(/<[^>]+>/g, '');
  var desc = stripedHtml.substring(0, 100) + '...';
  return (
    <div className={styles.blogContainer}>
      <div className={styles.blogImage}>
        <LazyLoadImage
          src={
            blog.better_featured_image?.source_url
              ? blog.better_featured_image?.source_url
              : '/placeholder.png'
          }
          alt=""
          className={styles.img}
        />
      </div>

      <div className={styles.dateDiv}>{str}</div>
      <div className={styles.blogTitle}>
        <a
          href={blog.link}
          target="_blank"
          rel="noopener"
          className={styles.links}
        >
          {blog.title.rendered}
        </a>
      </div>
      <div className={styles.blogContent}>{desc}</div>
      <a
        href={blog.link}
        target="_blank"
        rel="noopener"
        className={styles.btnDiv}
      >
        Read More
      </a>
    </div>
  );
};

export default BlogCard;
