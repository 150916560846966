import React from "react";
import { Card } from "@material-ui/core";
import { useSelector } from "react-redux";
import {
  strategyOptions,
  getFormattedValue,
  roundNum,
  formatCommaString,
  DRAK_GREEN_PLOOT,
  DARK_RED_PLOOT,
  RED_PLOOT,
  TEXT_BLACK,
} from "@plootus/common";
import RetirementChart from "../../../../Utils/Charts/RetirementChart/RetirementChart";
import { Row, Col } from "reactstrap";
import styles from "./GapGraph.module.css";
import Tooltip from "../../../../Utils/Tooltip/Tooltip";

const GapGraph = ({ myGraph, graphStyle, myStyle }) => {
  const graph = useSelector((state) => state.graphReducer);
  const amount = `$${formatCommaString(
    roundNum(Math.abs(graph.graph.results?.retirementGap), 0, 2)
  )}`;
  return (
    <div className={styles.myGraph} style={myGraph}>
      <div
        style={{
          height: "100%",
          maxHeight: "650px",
          minHeight: "450px",
        }}
      >
        <Row noGutters>
          <Col
            xs="12"
            style={{
              maxHeight: "60px",
            }}
          >
            <div className={styles.topContainer}>
              <div className={styles.topLeft}>
                <h5 className={styles.headerText}>
                  Retirement{" "}
                  {graph.graph.results?.retirementGap > 0 ? "Surplus" : "Gap"}
                </h5>
                <div
                  style={{
                    marginLeft: "5px",
                    marginTop: "5px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Tooltip
                    title="Based on anticipated retirement expenses and assets created until life expectancy."
                    placement="top"
                    render={(props) => (
                      <img
                        style={{
                          width: "15px",
                          height: "15px",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                        src="/question.png"
                        alt="question"
                      />
                    )}
                  />
                </div>
              </div>
              <div
                className={styles.topRight}
                style={{
                  color:
                    graph.graph.results?.retirementGap > 0
                      ? DRAK_GREEN_PLOOT
                      : DARK_RED_PLOOT,
                }}
              >
                {amount.slice(0, -2)}
                <span className={styles.afterDecimal}>{amount.slice(-2)}</span>
              </div>
            </div>
          </Col>
          <Col
            xs="12"
            style={{
              maxHeight: "150px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className={styles.cardHeader}>
              <Row noGutters>
                <Col xs="3">
                  <Row noGutters>
                    <Col
                      xs="9"
                      style={{
                        maxHeight: "100px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "70px",
                      }}
                    >
                      <div
                        className={styles.circle}
                        style={{
                          borderColor:
                            graph.graph.results.retirementGap > 0
                              ? DRAK_GREEN_PLOOT
                              : DARK_RED_PLOOT,
                        }}
                      >
                        <div className={styles.number}>
                          {graph.graph.results.retirementGap
                            ? getFormattedValue(
                                graph.graph.results.retirementGap,
                                "money",
                                2
                              )
                            : null}{" "}
                        </div>
                      </div>
                    </Col>
                    <Col
                      xs="3"
                      style={{
                        display: "flex",
                        maxHeight: "100px",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src="/equal-sign.png"
                        alt="equal"
                        className={styles.image}
                      />
                    </Col>
                    <Col
                      xs="10"
                      style={{
                        maxHeight: "50px",
                        textAlign: "center",
                      }}
                    >
                      <div className={styles.text}>
                        Retirement{" "}
                        {graph.graph.results?.retirementGap > 0
                          ? "Surplus"
                          : "Gap"}
                      </div>
                    </Col>
                    <Col xs="2"></Col>
                  </Row>
                </Col>
                <Col xs="6">
                  <Row noGutters>
                    <Col
                      xs="12"
                      style={{
                        maxHeight: "100px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div className={styles.container}>
                        <div className={styles.leftb}>
                          <img
                            src="/left.svg"
                            alt="left"
                            style={{ height: "70px" }}
                          />
                        </div>
                        <div className={styles.c1}>
                          <div className={styles.circle}>
                            <div className={styles.number}>
                              {graph.graph.results
                                ? getFormattedValue(
                                    graph.graph.results.retirementAssests,
                                    "money",
                                    1
                                  )
                                : null}{" "}
                            </div>
                          </div>
                        </div>
                        <div className={styles.plus}>
                          <img
                            src="/plus.png"
                            alt="plus"
                            className={styles.image}
                          />
                        </div>
                        <div className={styles.c2}>
                          <div className={styles.circle}>
                            <div className={styles.number}>
                              {graph.graph.results
                                ? getFormattedValue(
                                    graph.graph.results.retireReturns,
                                    "money",
                                    2
                                  )
                                : null}{" "}
                            </div>
                          </div>
                        </div>
                        <div className={styles.rightb}>
                          <img
                            src="/right.svg"
                            alt="right"
                            style={{ height: "70px" }}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col xs="12">
                      <div className={styles.middle}>
                        <div className={styles.downl}></div>
                        <div
                          xs="6"
                          style={{
                            maxHeight: "50px",
                          }}
                          className={styles.titlel}
                        >
                          <div className={styles.text}>
                            <p>Retirement Assets</p>
                          </div>
                        </div>
                        <div className={styles.downl}></div>
                        <div
                          xs="6"
                          style={{
                            maxHeight: "50px",
                          }}
                          className={styles.titler}
                        >
                          <div className={styles.text}>
                            <p>After Retirement Returns</p>
                          </div>
                        </div>
                        <div className={styles.downl}></div>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col xs="3">
                  <Row noGutters>
                    <Col
                      xs="3"
                      style={{
                        maxHeight: "100px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src="/minus.png"
                        alt="minus"
                        className={styles.image}
                      />
                    </Col>
                    <Col
                      xs="9"
                      style={{
                        maxHeight: "100px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "70px",
                      }}
                    >
                      <div
                        className={styles.circle}
                        style={{
                          borderColor: DARK_RED_PLOOT,
                          color: TEXT_BLACK,
                        }}
                      >
                        <div className={styles.number}>
                          {graph.graph.results
                            ? getFormattedValue(
                                graph.graph.results.futureExpenses,
                                "money",
                                2
                              )
                            : null}{" "}
                        </div>
                      </div>
                    </Col>
                    <Col xs="2"></Col>
                    <Col
                      xs="10"
                      style={{
                        fontSize: "14px",
                        textAlign: "center",
                      }}
                    >
                      <div className={styles.text}>
                        Projected Retirement Expenses
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Col>
          <Col xs="12" className={styles.myinnerG} style={myStyle}>
            <div style={{ height: "100%", ...graphStyle }}>
              <RetirementChart
                data={graph.graph.gap}
                color="#dc0615"
                xAxis="Age"
                yAxis="Remaining Retirement Assets"
                toolY="Remaining Assets"
              />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default GapGraph;
