import React, { useState, useEffect } from 'react';
import styles from './ManageAccounts.module.css';
import ManageTable from './Table/ManageTable';
import { useDispatch, useSelector } from 'react-redux';
import { getAccounts, BLUE_PLOOT, sendAlert } from '@plootus/common';
import CircularProgress from '@material-ui/core/CircularProgress';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import DataNotAvailable from '../../Utils/Texts/DataNotAvailable';
import Modal from 'react-modal';
import LinkModal from './LinkModal';
import back from '../../Assets/back.png';

const theme = createMuiTheme({
  palette: {
    primary: { main: BLUE_PLOOT }, // Purple and green play nicely together.
  },
  typography: { useNextVariants: true },
});

const ManageAccounts = () => {
  const dispatch = useDispatch();
  const { accounts, userData, token } = useSelector(
    (state) => state.userReducer
  );
  const [loading, setloading] = useState(true);
  const [clicked, setClicked] = useState(false);
  const { uuid } = userData;
  const [{ url, accessToken }, setData] = useState({
    url: '',
    accessToken: null,
  });
  useEffect(() => {
    (async () => {
      await dispatch(getAccounts(uuid));
      const config = {
        headers: { Authorization: token },
      };
      const response = await axios.get(
        'https://www.plootus.com/api/user/fastlink',
        config
      );
      setData({
        url: response.data.url,
        accessToken: response.data.accessToken,
      });
      setloading(false);
    })();
  }, []);

  if (userData.userType === 'advisor') {
    dispatch(
      sendAlert({
        title: 'Unauthorized',
        textContent: 'You are not allowed to access this screen',
      })
    );
    return <Redirect to="/auth/dashboard" />;
  }

  if (loading) {
    return (
      <div
        style={{
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <MuiThemeProvider theme={theme}>
          <CircularProgress size={50} />
        </MuiThemeProvider>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.right}>
          <button
            className={styles.button}
            onClick={() => setClicked(true)}
            id="btn-fastlink"
          >
            <i
              class="fa fa-link fa-flip-horizontal mr-2"
              aria-hidden="true"
            ></i>
            Link Accounts
          </button>
        </div>
      </div>
      <div className={styles.table}>
        {accounts?.length ? (
          <ManageTable
            data={accounts}
            loading={loading}
            setloading={setloading}
          />
        ) : (
          <DataNotAvailable box />
        )}
      </div>
      <Modal
        isOpen={clicked}
        onRequestClose={() => setClicked(false)}
        className={styles.Modal}
        overlayClassName={styles.Overlay}
      >
        <div className={styles.backCont}>
          <img
            src={back}
            onClick={() => {
              window.fastlink.close();
              setClicked(false);
            }}
            alt="back"
            style={{ height: '30px', width: '30px', cursor: 'pointer' }}
          />
        </div>
        <LinkModal url={url} accessToken={accessToken} clicked={clicked} />
      </Modal>
    </div>
  );
};

export default ManageAccounts;
