import React from 'react';
import { hydrate, render } from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import { store } from '@plootus/common';
import { BrowserRouter } from 'react-router-dom';
import { LastLocationProvider } from 'react-router-last-location';
import ContextProvider from './Reusables/VisualAndAccord/Context/Context';

const rootElement = document.getElementById('root');

if (rootElement.hasChildNodes()) {
  hydrate(
    <React.StrictMode>
      <Provider store={store}>
        <BrowserRouter>
          <LastLocationProvider>
            <ContextProvider>
              <App />
            </ContextProvider>
          </LastLocationProvider>
        </BrowserRouter>
      </Provider>
    </React.StrictMode>,
    rootElement
  );
} else {
  render(
    <React.StrictMode>
      <Provider store={store}>
        <BrowserRouter>
          <LastLocationProvider>
            <ContextProvider>
              <App />
            </ContextProvider>
          </LastLocationProvider>
        </BrowserRouter>
      </Provider>
    </React.StrictMode>,
    rootElement
  );
}
/**
 *  "reactSnap": {
    "inlineCss": true,
    "include": [
      "/",
      "/philosophy",
      "/security",
      "/401kCalculator",
      "/algorithm",
      "/termsandcondition",
      "/privacy",
      "/faq"
    ]
  },
 */
// render(
//   <React.StrictMode>
//     <Provider store={store}>
//       <BrowserRouter>
//         <LastLocationProvider>
//           <ContextProvider>
//             <App />
//           </ContextProvider>
//         </LastLocationProvider>
//       </BrowserRouter>
//     </Provider>
//   </React.StrictMode>,
//   rootElement
// );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
