import React, { useState } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import Footer from '../../Utils/Containers/Footer/Footer';
import NavBar from '../../Utils/Containers/Navbar/NavBar';
import CalculatorStatic from '../401kCalculatorStatic/CalculatorStatic';
import Error404 from '../404/Error404';
import Algorithm from '../Algorithm/Algorithm';
import StaticEmployer from '../Employer2/StaticEmployer';
import Faqs from '../FAQs/Faqs';
import HomePage from '../HomePage/HomePage';
import Philosophy from '../Philosophy/Philosophy';
import Privacy from '../Privacy/Privacy';
import Security from '../Security/Security';
import SocialSecurityModal from '../SocialSecurityCalculator/SocialSecurityCalculator';
import TermsAndCondition from '../TermsAndCondition/TermsAndCondition';
import LoginSignupContext from '../../Reusables/VisualAndAccord/Context/LoginSignupContext';
import { useEffect } from 'react';

const StaticPages = () => {
  const location = useLocation();
  const [signupModal, setSignupModal] = useState(false);
  const [loginModal, setLoginModal] = useState(
    location.query?.viaEmail || false
  );
  const [idx, setIdx] = useState(0);

  useEffect(() => {
    var Tawk_API = Tawk_API || {},
      Tawk_LoadStart = new Date();
    var s1 = document.createElement('script'),
      s0 = document.getElementsByTagName('script')[0];
    s1.async = true;
    s1.src = 'https://embed.tawk.to/5a57a3a2d7591465c706a90d/default';
    s1.charset = 'UTF-8';
    s1.setAttribute('crossorigin', '*');
    s0.parentNode.insertBefore(s1, s0);
  }, []);

  return (
    <div>
      <LoginSignupContext.Provider
        value={{
          signupModal,
          setSignupModal,
          loginModal,
          setLoginModal,
          idx,
          setIdx
        }}
      >
        <NavBar />
        <div>
          <Switch>
            <Route exact path='/' component={HomePage} />
            <Route exact path='/philosophy' component={Philosophy} />
            <Route exact path='/algorithm' component={Algorithm} />
            <Route exact path='/security' component={Security} />
            <Route exact path='/faq' component={Faqs} />
            <Route exact path='/employer' component={StaticEmployer} />
            <Route exact path='/401kCalculator' component={CalculatorStatic} />
            <Route
              exact
              path='/termsandcondition'
              component={TermsAndCondition}
            />
            <Route exact path='/privacy' component={Privacy} />
            {/* <Route path="/socialSecurity" component={SocialSecurityModal} /> */}
            <Route path='/' component={Error404} />
          </Switch>
        </div>

        <Footer />
      </LoginSignupContext.Provider>
    </div>
  );
};

export default StaticPages;
