import {
  employerNewDataSelector,
  strategy_constants,
  usePickerModal,
} from "@plootus/common";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import useWindowDimensions from "../../Reusables/VisualAndAccord/Hooks/useWindowDimensions";
import NewPie from "../../Utils/Charts/NewPie/NewPie";
import Picker from "../../Utils/Containers/Picker/Picker";
import styles from "./FundsOrEmployerList.module.css";
import FundsTable from "./FundsTable";
import MessageBox from "./MessageBox/MessageBox";

const FundsOrEmployerList = () => {
  const { funds } = useSelector(employerNewDataSelector);

  const [strategy, setStrategy] = useState(0);
  const [prevS, setPrevS] = useState(strategy);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setPrevS(strategy);
      setLoading(false);
    }, 200);
  }, [strategy]);

  const { picker, negativeModal, setIndex } = usePickerModal(
    (idx) => {
      setStrategy(idx);
    },
    strategy,
    true
  );

  const { width, height } = useWindowDimensions();

  return (
    <div className={styles.container}>
      {funds.length ? (
        <div className={styles.main}>
          <div className={styles.stratPicker}>
            <div className={styles.empty}></div>

            <div className={styles.strategy}>
              <div className={styles.myText}>SELECT A STRATEGY</div>
              <div className={styles.actualPicker}>
                <Picker
                  rows={strategy_constants}
                  {...picker}
                  handlePress={negativeModal}
                  setIndex={setIndex}
                  myStyle3={{ width: width > 1200 ? "110%" : "115%" }}
                  myStyle4={{ width: width > 1200 ? "110%" : "115%" }}
                />
              </div>
            </div>
          </div>

          <div className={styles.pieDiv}>
            <NewPie
              funds={funds}
              strategy={strategy}
              total={100}
              loading={loading}
            />
          </div>

          <div className={styles.tableDiv}>
            <FundsTable strategy={strategy} />
          </div>
        </div>
      ) : (
        <div className={styles.messageBox}>
          <MessageBox />
        </div>
      )}
    </div>
  );
};

export default FundsOrEmployerList;
