"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getFormatedDate = exports.toTitleCase = exports.roundNum = exports.formatCommaString = exports.generalMoney = exports.removeCommaString = exports.getFormattedValue = void 0;
var constants_1 = require("../constants/constants");
var utils_1 = require("./utils");
// Get formated values in M,K,B
exports.getFormattedValue = function (value, format, floor) {
    switch (format) {
        case 'money': {
            if ((value >= Math.pow(10, 12) && value > 0) ||
                (value < -(Math.pow(10, 12)) + 1 && value < 0)) {
                value = +exports.roundNum(value / Math.pow(10, 11), 1, 0) / 10;
                return "$" + value + "T";
            }
            if ((value >= Math.pow(10, 9) && value > 0) ||
                (value < -(Math.pow(10, 9)) + 1 && value < 0)) {
                value = +exports.roundNum(value / Math.pow(10, 8), 1, 0) / 10;
                return "$" + value + "B";
            }
            if ((value >= Math.pow(10, 6) && value > 0) ||
                (value < -(Math.pow(10, 6)) + 1 && value < 0)) {
                value = +exports.roundNum(value / Math.pow(10, 5), 1, 0) / 10;
                return "$" + value + "M";
            }
            if ((value >= 1000 && value > 0) || (value < -999 && value < 0)) {
                value = +exports.roundNum(value / Math.pow(10, 2), 1, 0) / 10;
                return "$" + value + "K";
            }
            return "$" + exports.roundNum(value, 0, 0);
        }
        case 'num':
            return value;
        case 'percent':
            return Math.round(value * 1000) / 10 + "%";
        default:
            return value;
    }
};
// Remove comma for formatted string
exports.removeCommaString = function (value) {
    return parseFloat(value.replace(/\$|,/g, ''));
};
exports.generalMoney = function (value) {
    return "$" + exports.formatCommaString(exports.roundNum(value, 0, 2, true));
};
// Add commas to int/float value
exports.formatCommaString = function (value) {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};
// Round of the number
exports.roundNum = function (num, pow, tofixed, abs, parseInt) {
    if (tofixed === void 0) { tofixed = 2; }
    if (abs === void 0) { abs = false; }
    if (parseInt === void 0) { parseInt = false; }
    var rounded = ((+(utils_1.isKhaliCheck(num) ? 0 : abs ? Math.abs(+num) : num) * Math.pow(10, pow)) /
        Math.pow(10, pow)).toFixed(tofixed);
    return parseInt ? +rounded : rounded;
};
// Convert to title case
function toTitleCase(str) {
    return str === null || str === void 0 ? void 0 : str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
}
exports.toTitleCase = toTitleCase;
// Removes the time from date and converts it to month name
function getFormatedDate(date, year, monthSlice) {
    if (year === void 0) { year = false; }
    if (monthSlice === void 0) { monthSlice = false; }
    var formattedDate = date.includes(' ') ? date === null || date === void 0 ? void 0 : date.split(' ')[0] : date;
    var d = new Date(formattedDate);
    var month = constants_1.monthNames[d.getMonth()];
    var myYear = year ? " " + d.getFullYear() : '';
    var mon = monthSlice ? month.slice(0, 3) : month;
    return (year ? mon.toUpperCase() : mon) + " " + d.getDate() + " " + myYear;
}
exports.getFormatedDate = getFormatedDate;
