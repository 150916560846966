import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import styles from './FooterBottom.module.css';
import Pdf from '../../../Assets/Plootus 401k Guide.pdf';
import { Link } from 'react-router-dom';
const FooterBottom = () => {
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    (async () => {
      const result = await Axios.get(
        'https://www.plootus.com/blog/?rest_route=/wp/v2/posts&per_page=1'
      );

      setBlogs(result.data);
    })();
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.heading}>
        <img src="/logo.png" alt="Plootus" />
        <p>©2018-2021 Analyze Future LLC | All rights reserved.</p>
      </div>
      <div className={styles.desc}>
        <p>
          By using this website/mobile application, you understand the
          information being presented is provided for informational purposes
          only and agree to our Terms of Use and Privacy Policy. Plootus relies
          on information from various sources believed to be reliable, including
          clients and third parties, but cannot guarantee the accuracy and
          completeness of that information. Nothing in this communication should
          be construed as an offer, recommendation, or solicitation to buy or
          sell any security. Additionally, Plootus does not provide tax advice
          and investors are advised to consult with a tax professional.
          Plootus’s financial advisory and planning services, provided to
          investors who become clients pursuant to a written agreement, are
          designed to aid our clients in preparing for their retirement and
          other financial goals. All investing involves risk, including the
          possible loss of money you invest, and past performance does not
          guarantee future performance.
        </p>
      </div>
      <div className={styles.bottom}>
        <div className={styles.support}>
          <div className={styles.head}>Support</div>
          <div className={styles.links}>
            <a className={styles.link} href="mailto:support@plootus.com">
              support@plootus.com
            </a>
            {blogs.length ? (
              <a
                href={blogs[0].link}
                target="_blank"
                rel="noopener"
                className={styles.link}
              >
                Blogs
              </a>
            ) : (
              ''
            )}
            <Link to={Pdf} target="_blank" className={styles.link}>
              401(k) from Dummies to Nerd
            </Link>
          </div>
        </div>

        <div className={styles.legal}>
          <div className={styles.head}>Legal</div>
          <div className={styles.links}>
            <Link to="/termsandcondition" className={styles.link}>
              Terms & Conditions
            </Link>
            <Link to="/privacy" className={styles.link}>
              Privacy Policy
            </Link>
            <Link to="/security" className={styles.link}>
              Security
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterBottom;
