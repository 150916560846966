import React from "react";
import PersonIcon from "@material-ui/icons/Person";
import styles from "./WorksText.module.css";

const WorksText = () => {
  return (
    <div className={styles.container}>
      <h1 className={styles.Htw}>How it Works ?</h1>
      <br />
      <br />
      <div className={styles.content}>
        <div className={styles.step}>
          <div className={styles.iconDiv}>
            <PersonIcon />
          </div>
          <h4 className={styles.stepper}>1-Step Process :</h4>
        </div>
        <br />
        <br />
        <br />
        <div className={styles.stepperb}>Search your employer 401k plan</div>
        <p className={styles.texts}>
          Search your employer 401k, 403b, 457 or TSP plans and select your
          investment strategy. Plootus will provide you the right investment
          allocations that save you fees and improve overall return based on
          Plootus patent-pending algorithm.
        </p>
      </div>
    </div>
  );
};

export default WorksText;
