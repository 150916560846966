import React, { useState, useEffect, useRef } from "react";
import SmallScreen from "../HomePage/EditAssumptions/SmallScreen";
import RetirementStatus from "../HomePage/EditAssumptions/RetirementStatus/RetirementStatus";
import { Card } from "@material-ui/core";
import Graph from "../HomePage/EditAssumptions/Graphs/AssetsGraph";
import GapGraph from "../HomePage/EditAssumptions/Graphs/GapGraph";
import EditAssumptions from "../EditAssumptions/EditAssumptions";
import styles from "./Calculator.module.css";
import useWindowDimensions from "../../Reusables/VisualAndAccord/Hooks/useWindowDimensions";
import { useSelector, useDispatch } from "react-redux";
import {
  calculateRetirementGap,
  isAdvisorSelector,
  getCurrentAge,
  calculateRetirementAssessts,
} from "@plootus/common";
import FutureExpenses from "../FutureExpenses/FutureExpenses";
import SmallFutureGraph from "../Dashboard/Graphs/SmallFutureGraph";
import SmallFutureModal from "../Dashboard/SmallFutureModal";

const Calculator = () => {
  const { width } = useWindowDimensions();
  const graph = useSelector((state) => state.graphReducer);
  const currentAgeRef = useRef();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.userReducer);

  const [modalIsOpen, setModalOpen] = useState(false);
  const [amodalIsOpen, setAModal] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const amodalOpen = () => {
    setAModal(true);
  };
  const amodalClose = () => {
    setAModal(true);
  };

  useEffect(() => {
    let dob;
    if (user.token) {
      if (user.userData.userType === "advisor")
        dob = user.advisor?.clients[user.advisor?.selectedClient]?.dob;
      else dob = user?.userData?.dob;

      currentAgeRef.current = getCurrentAge(dob || "1991-01-01");
    } else {
      currentAgeRef.current = graph.sliderOptions.currentAge?.value;
    }
    const { retireAge, contribution } = graph.sliderOptions;
    // calling utility function for calculating assets
    calculateRetirementAssessts(
      retireAge.value,
      currentAgeRef.current,
      graph.openingBalance,
      contribution.value,
      graph.strategyIndex,
      dispatch
    );
  }, [graph.sliderOptions, graph.strategyIndex, graph.openingBalance]);
  // Calculate retirement gap chart only when the assets are calculated
  // so the dependency is graph.graph.results.retirementAssests
  useEffect(() => {
    const { retireAge, lifeExp } = graph.sliderOptions;
    // calling utility function for calculating retirement gap
    debugger;
    calculateRetirementGap(
      retireAge.value,
      lifeExp.value,
      graph.strategyIndex,
      graph.expense_home,
      graph.graph.results.retirementAssests,
      graph.socialSecurity,
      dispatch
    );
  }, [
    graph.graph.results.retirementAssests,
    graph.sliderOptions.retireAge.value,
    graph.sliderOptions.lifeExp.value,
  ]);

  return (
    <div className={styles.row2}>
      {width <= 1280 ? (
        <>
          <div className={styles.smallscreen}>
            <SmallScreen
              myStyle={{ height: width < 576 ? "260px" : "350px" }}
              myStyle2={{ height: width < 576 ? "240px" : "350px" }}
            />
          </div>
          <div className={styles.futureParent}>
            <div className={styles.smallFuture}>
              <div className={styles.smallFutureHeading}>
                <h4>Retirement Expenses </h4>
              </div>
              <SmallFutureGraph />
            </div>
            <div className={styles.smallFutureModal}>
              <SmallFutureModal />
            </div>
          </div>
        </>
      ) : (
        <>
          <div className={styles.title}>
            401k Calculator{" "}
            {graph.graph.results.retirementGap < 0 ? (
              <RetirementStatus
                dashy={true}
                status={false}
                modalIsOpen={amodalIsOpen}
                closeModal={amodalClose}
                aopenModal={amodalOpen}
                setModalOpen={setAModal}
              />
            ) : (
              <div>
                <img src="/check.svg" alt="" className={styles.exclaim} />
              </div>
            )}
          </div>

          <div className={styles.graphCont}>
            <Card
              style={{
                boxShadow: "9px 7px 15px rgba(0,0,0,.05)",
                border: "2px solid #ededed",
                borderRadius: "10px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div className={styles.upper}>
                <div className={styles.graph1}>
                  <Graph
                    myGraph={{ padding: "0px" }}
                    myStyle={{ height: "380px" }}
                  />
                </div>
                <div className={styles.graph2}>
                  <GapGraph
                    myGraph={{ padding: "0px" }}
                    myStyle={{ height: "380px" }}
                  />
                </div>
              </div>

              <div className={styles.bottomText}>
                <RetirementStatus
                  status={graph.graph.results.retirementGap > 0}
                  modalIsOpen={modalIsOpen}
                  setModalOpen={setModalOpen}
                />
              </div>
            </Card>
          </div>
          <div className={styles.editA}>
            <div className={styles.editCard}>
              <EditAssumptions editMode={editMode} setEditMode={setEditMode} />
            </div>
          </div>
          <div className={styles.future}>
            <FutureExpenses />
          </div>
        </>
      )}
    </div>
  );
};

export default Calculator;
