import { Card } from "@material-ui/core";
import {
  calculateRetirementAssessts,
  calculateRetirementGap,
  GENERAL_GRAPH,
  getEditAssumptionsInitialState,
  useDidUpdate,
} from "@plootus/common";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useWindowDimensions from "../../../Reusables/VisualAndAccord/Hooks/useWindowDimensions";
import styles from "./EditAssumptions.module.css";
import EditSlider from "./EditSlider";
import Graph from "./Graphs/AssetsGraph";
import GapGraph from "./Graphs/GapGraph";
import RetirementSignupMain from "./RetirementStatus/RetirementSignupMain";
import SmallScreen from "./SmallScreen";

//import EditSlider from "./EditSlider";

const EditAssumptions = () => {
  const currentAgeRef = useRef();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.userReducer);
  const graph = useSelector((state) => state.graphReducer);

  useEffect(() => {
    (async () => {
      dispatch({
        type: GENERAL_GRAPH,
        payload: await getEditAssumptionsInitialState(null, null),
      });
    })();
  }, []);

  useDidUpdate(() => {
    currentAgeRef.current = graph.sliderOptions?.currentAge?.value;

    //console.log(currentAgeRef.current);
    const { retireAge, contribution } = graph.sliderOptions;
    // calling utility function for calculating assets
    calculateRetirementAssessts(
      retireAge.value,
      currentAgeRef.current,
      graph.openingBalance,
      contribution.value,
      graph.strategyIndex,
      dispatch
    );
  }, [graph?.sliderOptions, graph?.strategyIndex, graph?.openingBalance]);

  //console.log(graph.sliderOptions?.expenses.value);
  useDidUpdate(() => {
    const { retireAge, lifeExp } = graph.sliderOptions;
    // calling utility function for calculating retirement gap
    calculateRetirementGap(
      retireAge?.value,
      lifeExp?.value,
      graph?.strategyIndex,
      user.token ? graph.expenses_home : graph.sliderOptions?.expenses.value,
      graph.graph?.results?.retirementAssests,
      graph.socialSecurity,
      dispatch
    );
  }, [
    graph.graph?.results?.retirementAssests,
    graph.sliderOptions?.lifeExp,
    graph.sliderOptions?.expenses,
  ]);

  const { height, width } = useWindowDimensions();
  const [showText, setShowText] = useState(false);
  if (width <= 868) {
    return (
      <SmallScreen
        myStyle={{ height: "260px" }}
        myStyle2={{ height: "240px" }}
      />
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.assets}>
        <Card
          style={{
            boxShadow: "9px 7px 15px rgba(0,0,0,.05)",
            border: "2px solid #ededed",
            borderRadius: "10px",
            width: "100%",
          }}
        >
          <Graph graphStyle={{ maxHeight: "370px" }} />
        </Card>
      </div>
      <div className={styles.gap}>
        <Card
          style={{
            boxShadow: "9px 7px 15px rgba(0,0,0,.05)",
            border: "2px solid #ededed",
            borderRadius: "10px",
            width: "100%",
          }}
        >
          <GapGraph graphStyle={{ maxHeight: "370px" }} />
        </Card>
      </div>
      <div className={styles.edit}>
        <EditSlider setShowText={setShowText} />
      </div>
      <div className={styles.text}>
        {showText && (
          <RetirementSignupMain
            status={graph.graph.results.retirementGap > 0}
          />
        )}
      </div>
    </div>
  );
};

export default EditAssumptions;
