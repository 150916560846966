import React from "react";
import { formatCommaString, roundNum, DARK_RED_PLOOT } from "@plootus/common";
import styles from "./TableHead.module.css";

const TableHead = ({ totalCurrent, totalFuture, from, years, inflation }) => {
  return (
    <div className={styles.container}>
      <div className={`${styles.col1} ${styles.col}`}>
        <div className={styles.title}>Category</div>
        <div className={styles.belowTitle}>{"   "}</div>
        <div className={styles.amtDiv} style={{ color: DARK_RED_PLOOT }}>
          {" "}
        </div>
      </div>
      <div className={`${styles.col2} ${styles.col}`}>
        <div className={styles.title}>Current Expenses</div>

        <div className={styles.belowTitle} style={{ color: "#f8f8f8" }}></div>
        <div className={styles.amtDiv} style={{ color: DARK_RED_PLOOT }}>
          {`$${formatCommaString(roundNum(totalCurrent, 0, 0, true, true))}`}
        </div>
      </div>
      <div className={`${styles.col3} ${styles.col}`}>
        <div className={styles.title}>Retirement Expenses</div>
        <div className={styles.belowTitle}>without inflation</div>
        <div
          className={styles.amtDiv}
          style={{ color: DARK_RED_PLOOT, bottom: 0 }}
        >
          {`$${formatCommaString(roundNum(totalFuture, 0, 0, true, true))}`}
        </div>
      </div>
      <div className={`${styles.col4} ${styles.col}`}>
        <div className={styles.title}>Retirement Expenses</div>
        <div className={styles.belowTitle}>
          with inflation<span style={{ color: "#f8f8f8" }}>out</span>
        </div>
        <div
          className={styles.amtDiv}
          style={{ color: DARK_RED_PLOOT, bottom: 0 }}
        >{`$${formatCommaString(
          roundNum(
            totalFuture * (1 + inflation / 100) ** years,
            0,
            0,
            true,
            true
          )
        )}`}</div>
      </div>
    </div>
  );
};

export default TableHead;
