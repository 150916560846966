"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.usePrevious = void 0;
var react_1 = require("react");
// To get the previous value which isnt same due to rerender of component
exports.usePrevious = function (value) {
    var ref = react_1.useRef();
    var ref2 = react_1.useRef();
    react_1.useEffect(function () {
        ref.current = value;
    });
    if (value === ref.current) {
        return ref2.current;
    }
    ref2.current = ref.current;
    return ref.current;
};
exports.default = exports.usePrevious;
