import React, { useEffect, useState } from "react";
import styles from "./FutureExpenses.module.css";
import {
  get_future_expense_planner,
  getBirthYear,
  getDate,
  BLUE_PLOOT,
  getFormattedValue,
  GENERAL_FUTURE_EXPENSES_TABLE,
  DARK_RED_PLOOT,
  isAdvisorSelector,
  currentClientSelector,
  TEXT_BLACK_2,
  GREEN_PLOOT,
} from "@plootus/common";
import { useDispatch, useSelector } from "react-redux";
import FromToPicker from "./FromToPicker";
import BarGraph from "../../Utils/Charts/BarGraph/BarGraph";
import CircularProgress from "@material-ui/core/CircularProgress";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import Table from "./Table";
import DataNotAvailable from "../../Utils/Texts/DataNotAvailable";

const theme = createMuiTheme({
  palette: {
    primary: { main: BLUE_PLOOT }, // Purple and green play nicely together.
  },
  typography: { useNextVariants: true },
});

const FutureExpenses = () => {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.userReducer);
  const general = useSelector((state) => state.generalReducer);
  const graph = useSelector((state) => state.graphReducer);
  const state = useSelector((state) => state);

  const dob = isAdvisorSelector(state)
    ? currentClientSelector(state).dob
    : user.userData.dob;

  const { retireAge, lifeExp } = graph.sliderOptions;

  const { inflation, graphData, total } = general.futureExpenses;

  const birth_year = getBirthYear(dob || "1981-01-01");
  const pickerYears = +lifeExp.value - +retireAge.value;

  const [fromPicker, setFromPicker] = useState({
    index: 0,
    visible: false,
  });
  const [toPicker, setToPicker] = useState({
    index: pickerYears,
    visible: false,
  });

  const fromYear = birth_year + retireAge.value;
  const toYear = birth_year + lifeExp.value;

  const rows1 = [];
  const rows2 = [];

  const [loading, setloading] = useState(true);

  for (let i = parseInt(fromYear, 10); i <= parseInt(toYear, 10); i += 1) {
    rows1.push({ label: i });
    rows2.push({ label: i });
  }

  const [responseData, setResponseData] = useState(null);

  useEffect(() => {
    // Replace this api call with new api call
    setloading(true);
    setToPicker((prev) => ({
      ...prev,
      index: +lifeExp.value - +retireAge.value,
    }));
    console.log("Future retire Age : ", retireAge, "+++++++++");
    getFutureExpenses();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromPicker.index, retireAge.value]);

  const getFutureExpenses = async () => {
    let response = await dispatch(
      get_future_expense_planner(rows1[fromPicker.index].label)
    );

    //
    setResponseData(response);
    setloading(false);
  };

  const rowData = [];
  const graph_data = []; // Data  points for graph
  let current = 0; // Get the total for current values of expense
  let future = 0; // Get the total for retirement values of expense

  // let home_total = 0; // Total for future expense graph (home page)

  // If Action is for Table
  // Data for table row, add a changed attribute to have a reset functionality

  let data, years;

  useEffect(() => {
    if (responseData) {
      responseData.futureExpenses.map((item) => {
        if (item.category === "Social Security") {
          return;
        }
        rowData.push({ ...item, changed: false });
        // Add non null values

        current += item.current ? parseFloat(item.current) : 0; // Current values total
        future += item.future ? parseFloat(item.future) : 0; // Future values total
      });

      let y_data = future * (1 + inflation / 100) ** years;
      let yData;
      let newTotal = 0;

      const getBenefit = (age) => {
        const item = responseData.socialSecurity.filter(
          (item) => item.Age === age
        );
        return item.length > 0 ? item[0].Benefits : 0;
      };

      // Future expenses graph (x axis -> year, y axis -> expense value)
      // arg2 -> from year, arg3 -> to year
      let benefit = 0;
      for (
        let i = rows1[fromPicker.index].label;
        i < rows2[toPicker.index].label;
        i += 1
      ) {
        yData = y_data;
        benefit = getBenefit(i - birth_year);
        graph_data.push({
          x: i - birth_year,
          y: yData - benefit > 0 ? -(yData - benefit) : 0,
        });
        y_data = yData * (1 + inflation / 100); // Increases with inflation every year
        newTotal += yData - benefit; // Total future expenses
      }

      const payload = {
        listData: rowData,
        currentTotal: current,
        futureTotal: future,
        // graphData: graph_data.concat(socialSecurity),
        graphData: graph_data,
        total: newTotal > 0 ? Math.round(newTotal * 10) / 10 : 0,
      };

      dispatch({
        type: GENERAL_FUTURE_EXPENSES_TABLE,
        payload,
      });
    }
  }, [responseData, toPicker.index]);

  years = +rows1[fromPicker.index].label - +getDate().getFullYear();

  if (graphData)
    data = graphData.map((item, idx) => {
      return { ...item, y: -item.y };
    });

  // *************************************************

  return !loading ? (
    <div className={styles.root}>
      {total >= 0 ? (
        <div className={styles.totalDiv}>
          <div className={styles.part1}>
            <div className={styles.header}>
              <div className={styles.left}>Retirement Expenses</div>
              <div className={styles.right} style={{ color: DARK_RED_PLOOT }}>
                {getFormattedValue(total, "money", 2)}
              </div>
            </div>
            <div>
              <BarGraph
                sampleData={data}
                showTickX={true}
                showTickY={true}
                gType="future"
                color1={BLUE_PLOOT}
                color2={GREEN_PLOOT}
                giveMargin={false}
                borderRadius={10}
                myBarRatio={0.2}
                barWidth={20}
                xAxisColor="lightgrey"
                yAxisColor="none"
                yUnit="$"
                fontSize={23}
                yLabelShow={true}
                showTooltip={true}
              />
            </div>
            <div>
              <FromToPicker
                total={total}
                rows1={rows1}
                rows2={rows2}
                fromPicker={fromPicker}
                setFromPicker={setFromPicker}
                toPicker={toPicker}
                setToPicker={setToPicker}
              />
            </div>
          </div>
          <div className={styles.part2}>
            <Table
              from={+rows1[fromPicker.index].label}
              to={toYear}
              years={years}
              dob={birth_year}
              pickerYears={pickerYears}
              getFutureExpenses={getFutureExpenses}
            />
          </div>
        </div>
      ) : (
        <>
          <div className={styles.header}>
            <div className={styles.left}>Retirement Expenses</div>

            <div
              className={styles.value}
              style={{
                color: TEXT_BLACK_2,
              }}
            >
              {"$ --"}
            </div>
          </div>
          <DataNotAvailable />
        </>
      )}
    </div>
  ) : (
    <div className={styles.loader}>
      <MuiThemeProvider theme={theme}>
        <CircularProgress size={50} />
      </MuiThemeProvider>
    </div>
  );
};

export default FutureExpenses;
