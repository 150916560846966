"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useRenderCount = void 0;
var react_1 = require("react");
// Used to check how many times a component rendered
exports.useRenderCount = function (screen) {
    var ref = react_1.useRef(0);
    console.log(ref.current++, "" + screen);
};
exports.default = exports.useRenderCount;
