import React, { useState } from "react";
import styles from "./ExpenseRow.module.css";
import {
  roundNum,
  formatCommaString,
  isKhaliCheck,
  useDidUpdate,
} from "@plootus/common";
import reset from "../../Assets/reset.svg";

const ExpenseRow = ({
  category,
  future,
  current,
  flag,
  futureInf,
  changed,
  new_future,
  index,
  resetItem,
  setCurrentEdit,
  isEditing,
}) => {
  const future_value = changed ? new_future : future;

  const [val, setVal] = useState(
    `$${formatCommaString(roundNum(future_value, 0, 0, true, true))}`
  );

  // COPY PASTE

  const handleInputChange = (value) => {
    let stripped = value.replace(/\$|,/g, "");
    let j = 0;
    for (let i = 0; i < stripped.length; i += 1) {
      if (stripped[i] === "0") {
        j += 1;
      } else {
        break;
      }
    }

    stripped = stripped.slice(j);

    if (isKhaliCheck(stripped)) {
      setVal("$0");
    }
    if (/^\d+$/.test(stripped)) {
      setVal(`$${formatCommaString(stripped)}`);
    }

    if (val === value) {
      resetItem(index);
    }
  };

  // Handle on Typing
  useDidUpdate(() => {
    const fv = changed
      ? future_value
      : `$${formatCommaString(roundNum(future_value, 0, 0, true, true))}`;

    if (val !== fv) {
      setCurrentEdit((prevItems) => {
        const updatedItems = new Map(prevItems);
        updatedItems.set(index, val);

        return updatedItems;
      });
    }
  }, [val]);

  // Handle Cancle Press
  useDidUpdate(() => {
    if (!isEditing) {
      setVal(
        changed
          ? future_value
          : `$${formatCommaString(roundNum(future_value, 0, 0, true, true))}`
      );

      setCurrentEdit((prevItems) => {
        const updatedItems = new Map(prevItems);
        updatedItems.delete(index);
        return updatedItems;
      });
    }
  }, [isEditing]);

  // If redux data udpates update InputTextField Value
  useDidUpdate(() => {
    setVal(
      changed
        ? future_value
        : `$${formatCommaString(roundNum(future_value, 0, 0, true, true))}`
    );
  }, [future_value]);

  /************************************/
  const newCat = category.replace(/\//g, "/ ");
  return (
    <div
      className={`${styles.container} ${
        index % 2 == 0 ? styles.on : styles.off
      } `}
    >
      <div className={`${styles.col1} ${styles.col}`}>
        <div className={styles.catTitle}>{`${newCat}`}</div>
        {changed || flag === 1 ? (
          <div className={styles.catImage}>
            <img
              src={reset}
              alt=""
              className={styles.questionImage}
              onClick={() => resetItem(index)}
            />
          </div>
        ) : null}
      </div>
      <div className={`${styles.col2} ${styles.col}`}>
        <div className={styles.cell2Text}>
          {`$${formatCommaString(roundNum(current, 0, 0, true, true))}`}
        </div>
      </div>
      <div className={`${styles.col3} ${styles.col}`}>
        <input
          value={val}
          className={styles.inputVal}
          onChange={(e) => handleInputChange(e.target.value)}
          maxLength={12}
        />
      </div>
      <div className={`${styles.col4} ${styles.col}`}>
        <div className={styles.cell2Text}>
          {`$${formatCommaString(roundNum(futureInf, 0, 0, true, true))}`}
        </div>
      </div>
    </div>
  );
};

export default ExpenseRow;
