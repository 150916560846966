import React from 'react';
import styles from './Faqs.module.css';
import image from '../../Assets/faqs.svg';
import Accordion from '../../Utils/Accordion/Accordion';
import { faqs } from './Questions';
import { Helmet } from 'react-helmet';

function Faqs() {
  window.scrollTo(0, 0);
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Answers to Your Questions | Plootus</title>
        <meta
          name="description"
          content="Get all your questions answered under one roof. Simple explaination of 401k, 403b, IRA and 457 plans. We at Plootus try to provide answers to unanswered ones."
        />
      </Helmet>
      <div className={styles.gapper} />
      <div className={styles.main}>
        <div className={styles.textContent}>
          <h1 className={styles.philoH1}>Frequently Asked Questions</h1>
          <p className={styles.philoP}>
            Here are answers to some of the questions asked by our users.
          </p>
        </div>
        <div className={styles.imageContent}>
          <img
            src={image}
            alt="Youth is less concerned with Retirement Planning. Get Your Free advice here at Plootus"
            className={styles.img}
          />
        </div>
      </div>
      <div className={styles.faqSection}>
        <div className={styles.faqLeft}>
          <div className={styles.faqLeftText}>
            <h2 className={styles.faqHeader}>
              Do you have any query regarding Plootus?
            </h2>
            <p className={styles.faqDesc}>
              Contact us anytime, and post your query below
            </p>
          </div>
        </div>
        <div className={styles.faqRight}>
          <div className={styles.AccordionContainer}>
            {faqs.map((faq, i) => {
              let first = false,
                last = false;
              if (i === 0) {
                first = true;
              }
              if (i === faqs.length - 1) last = true;
              return (
                <Accordion
                  title={faq.title}
                  first={first}
                  last={last}
                  index={i}
                >
                  <span className="accordion-text">{faq.content}</span>
                </Accordion>
              );
            })}
          </div>
        </div>
      </div>
      {/* <div className={styles.feedback}>
        <div className='container'>
          <h2 className={styles.feedbackHeader}>How can we help ?</h2>
          <h6 className={styles.feedbackDesc}>
            Our team is here for you. We will get back to you in 2 business
            days.
          </h6>

          <form method='POST' action='#' class='mt-5'>
            <div className='row'>
              <div className='col-md-4 form-group'>
                <label for='formGroupExampleInput' className={styles.label}>
                  Full Name
                </label>
                <input
                  type='text'
                  className={`form-control ${styles.input}`}
                  id='formGroupExampleInput'
                  placeholder='Enter your full name'
                  required
                />
              </div>
              <div className='col-md-4 form-group'>
                <label for='formGroupExampleInput' className={styles.label}>
                  Email Address
                </label>
                <input
                  type='email'
                  className={`form-control ${styles.input}`}
                  id='formGroupExampleInput'
                  placeholder='Enter your Email Id'
                  required
                />
              </div>
              <div className='col-md-4 form-group'>
                <label for='formGroupExampleInput' className={styles.label}>
                  Phone Number
                </label>
                <input
                  type='number'
                  className={`form-control ${styles.input}`}
                  id='formGroupExampleInput'
                  placeholder='Enter your Phone no.'
                  required
                />
              </div>

              <div className='col-12 form-group'>
                <label for='formGroupExampleInput' className={styles.label}>
                  Query
                </label>
                <textarea
                  name=''
                  id=''
                  cols='30'
                  rows='5'
                  className={`form-control ${styles.input}`}
                  required
                ></textarea>
              </div>
            </div>
            <div
              className='col-12 button-div'
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <button type='submit' className='btn btn-primary'>
                Send Message
              </button>
            </div>
          </form>
        </div>
      </div> */}
    </div>
  );
}

export default Faqs;
