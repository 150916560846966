import React, { useState } from 'react';
import styles from './Picker.module.css';

const Picker = ({
  rows,
  index,
  setIndex,
  myStyle,
  myStyle2,
  myStyle3,
  myStyle4,
}) => {
  const [active, setActive] = useState(false);
  const [variable, setVariable] = useState(rows[index]?.label);
  return (
    <div className={styles.container} style={myStyle}>
      <div className={`${styles.selectbox} `} style={myStyle2}>
        <div
          className={
            active ? styles.optionsContainerActive : styles.optionsContainer
          }
          style={myStyle4}
        >
          {rows.map((row, idx) => (
            <div
              className={styles.option}
              onClick={() => {
                setActive(false);
                setVariable(row.label);
                setIndex(idx);
              }}
            >
              <input
                type="radio"
                className={styles.radio}
                id={row.label}
                name="category"
                onClick={() => {
                  setActive(false);
                  setVariable(row.label);
                  setIndex(idx);
                }}
                key={idx}
                checked={variable === row.label ? true : false}
              ></input>
              <label htmlFor={row.label} style={{ cursor: 'pointer' }}>
                {row.label}
              </label>
            </div>
          ))}
        </div>
        <div
          className={styles.selected}
          style={myStyle3}
          onClick={() => setActive((prevState) => !prevState)}
        >
          {variable}
        </div>
      </div>
    </div>
  );
};

export default Picker;
