import {
  DRAK_GREEN_PLOOT,
  formatCommaString,
  generalUserAction,
  roundNum,
  TEXT_BLACK_1,
  fetch_allocations,
  TEXT_BLACK_2,
  feesSavedDefault
} from '@plootus/common';

import SearchIcon from '@material-ui/icons/Search';

import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import SearchBox401k from '../../Utils/Search401kNew/SearchBox401kNew';
import useEmployerSearch from '../../Reusables/VisualAndAccord/Hooks/useEmployerSearch';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useDispatch } from 'react-redux';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { useLocation, useHistory } from 'react-router-dom';
import Typist from 'react-typist';
import img1 from '../../Assets/home-images/img1.png';
import img2 from '../../Assets/home-images/img2.png';
import img3 from '../../Assets/home-images/img3.png';
import LoginSignupContext from '../../Reusables/VisualAndAccord/Context/LoginSignupContext';
import { SocialSecurityContext } from '../../Reusables/VisualAndAccord/Context/SocialSecurityContext';
import FeesGraph from '../Dashboard/Graphs/FeesGraph';
import LogIn from '../LogIn/LogIn';
import MainSignupScreen from '../SignUp/MainScreen';
import Blogs from './Blogs/Blogs';
import styles from './HomePage.module.css';
import useWindowDimensions from '../../Reusables/VisualAndAccord/Hooks/useWindowDimensions';
import MobileCarousel from './HowItWorks/MobileCarousel';
import WorksText from './HowItWorks/WorksText';
import MyCarousel from './MyCarousel';

const HomePage = (props) => {
  /* Login and Signup for Fees Saved */
  const [spath, setSpath] = useState(true);
  const history = useHistory();
  const location = useLocation();
  const { width } = useWindowDimensions();

  const { signupModal, setSignupModal, setLoginModal, loginModal } = useContext(
    LoginSignupContext
  );
  const { openSocialSecurity } = useContext(SocialSecurityContext);
  const prop = useEmployerSearch();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      generalUserAction({
        viaEmail: location.query?.viaEmail,
        userAdvisorId: location.query?.userAdvisorId
      })
    );
  }, []);

  const signupopenModal = () => {
    setSignupModal(true);
  };

  const signupcloseModal = () => {
    setSignupModal(false);
  };
  const loginopenModal = () => {
    setLoginModal(true);
  };

  const logincloseModal = () => {
    setLoginModal(false);
  };

  const [count, setCount] = useState(1);
  const [startType, setStartType] = useState(true);
  useEffect(() => {
    setCount(1);
  }, [count]);

  const total = feesSavedDefault[3].total;

  const amount = `$${formatCommaString(roundNum(total, 0, 2))}`;
  const empLogos = [
    {
      amazon: require('../../Assets/employee-logos/amazon.png'),
      ein: '911986545',
      name: 'AMAZON.COM SERVICES,INC.'
    },
    {
      amazon: require('../../Assets/employee-logos/ge.png'),
      ein: '140689340',
      name: 'GENERAL ELECTRIC COMPANY'
    },
    {
      amazon: require('../../Assets/employee-logos/fb.png'),
      ein: '201665019',
      name: 'FACEBOOK, INC.'
    },
    {
      amazon: require('../../Assets/employee-logos/testla.png'),
      ein: '140689340',
      name: 'GENERAL ELECTRIC COMPANY'
    },
    {
      amazon: require('../../Assets/employee-logos/apple.png'),
      ein: '942404110',
      name: 'APPLE INC.'
    },
    {
      amazon: require('../../Assets/employee-logos/star.png'),
      ein: '911325671',
      name: 'STARBUCKS CORPORATION'
    },
    {
      amazon: require('../../Assets/employee-logos/nissan.png'),
      ein: '140689340',
      name: 'GENERAL ELECTRIC COMPANY'
    },
    {
      amazon: require('../../Assets/employee-logos/newyork.png'),
      ein: '135562308',
      name: 'NEW YORK UNIVERSITY'
    },
    {
      amazon: require('../../Assets/employee-logos/fairuni.png'),
      ein: '60646623',
      name: 'FAIRFIELD UNIVERSITY'
    },
    {
      amazon: require('../../Assets/employee-logos/alabama.png'),
      ein: '140689340',
      name: 'GENERAL ELECTRIC COMPANY'
    },
    {
      amazon: require('../../Assets/employee-logos/sacri.png'),
      ein: '140689340',
      name: 'GENERAL ELECTRIC COMPANY'
    },
    {
      amazon: require('../../Assets/employee-logos/michan.png'),
      ein: '140689340',
      name: 'GENERAL ELECTRIC COMPANY'
    }
  ];
  //   useEffect(()=>{
  //     if (spath) {
  //     if(prop.employer_selected!=""){
  // history.push('/employer');
  //     }

  //   }

  //   },[prop.employer_selected])

  return (
    <div className={styles.root}>
      <Helmet>
        <meta charSet='utf-8' />
        <title>
          Simplified Retirement Planning | 401k 403b 457 IRA | Plootus
        </title>
        <meta
          name='description'
          content='We Will Optimize 401k, IRA, 403b, 457 plans for you. Our free retirement management algorithm will help you optimize your investments. Link your employer in Plootus to find out.'
        />
        <meta
          name='keywords'
          content='Retirement calculator, Advisory services, 401k allocation, 403b optimization, 457 plan, TSP, best Retirement Plans,  how to manage 401k, low cost account management, Plan Rollover, IRA, deferred compensation,Retirement early withdrawl, Financial Planning, Financial advisor'
        />
      </Helmet>
      {startType ? (
        <>
          <div className={styles.main}>
            <div className={styles.text}>
              {width > 700 ? (
                <>
                  <div className={styles.searchParent}>
                    <div className={styles.inputWithIcon}>
                      <div className={styles.searchCont}>
                        <SearchIcon
                          style={{
                            fontSize: width > 576 ? '25px' : '20px'
                          }}
                          className={styles.inputIcon}
                        />
                      </div>
                      <div className={styles.inputCont}>
                        <input
                          type='text'
                          onFocus={() => setStartType(false)}
                          placeholder="Search your Employer's 401k Plan"
                          className={styles.inputField}
                          // onBlur={() => setShowList(false)}
                        />
                      </div>
                    </div>
                    {/* <SearchBox401k {...prop} fromStatic={true}  /> */}
                  </div>
                  <div style={{ marginTop: '30%' }} />
                </>
              ) : null}

              <div className={styles.typingText}>
                {count ? (
                  <Typist onTypingDone={() => setCount(0)}>
                    <span>401k Simplified.</span>
                    <Typist.Backspace count={16} delay={2000} />
                    <span>Save Fees.</span>
                    <Typist.Backspace count={10} delay={2000} />
                    <span>Increase 401k Returns.</span>
                    <Typist.Backspace count={22} delay={2000} />
                    <span>Get Unbiased Advice.</span>
                    <Typist.Backspace count={20} delay={2000} />
                  </Typist>
                ) : (
                  ''
                )}
              </div>
              <div className={styles.lowerText}>
                <div className={styles.textUp}>
                  Search your 401k, 403b, 457, TSP plans and get absolutely FREE
                  advice to pick the right investements.
                </div>
                <div className={styles.buttons}>
                  <button className={styles.googlePLay}>
                    <a
                      href='https://play.google.com/store/apps/details?id=com.plootus.android'
                      className={styles.link}
                    >
                      <img
                        src='/googlePlay.png'
                        height='25px'
                        width='25px'
                        alt=''
                      />{' '}
                      Google Play
                    </a>
                  </button>
                  <button className={styles.appStore}>
                    <a
                      href='https://apps.apple.com/us/app/plootus/id1311669590'
                      className={styles.link}
                    >
                      <img
                        src='/appleLogo.png'
                        height='20px'
                        width='20px'
                        style={{ marginBottom: '3px', marginRight: '5px' }}
                        alt=''
                      />{' '}
                      App Store
                    </a>
                  </button>
                </div>
                {/* <div className={styles.socialsecurityDiv}>
          <div>
            <p>
              Ever wondered how much you might receive in Social Security?
            </p>
            <p>
              Use our Social Security benefit calculator to estimate your
              retirement benefits
            </p>
          </div>
          <div>
            <button
              className={styles.socialsecurityButton}
              onClick={() => openSocialSecurity()}
            >
              Social Security Calculator
            </button>
          </div>
        </div> */}
              </div>
            </div>

            <div className={styles.mySvg}>
              {width < 576 ? (
                <>
                  <div className={styles.searchParent}>
                    <SearchBox401k {...prop} fromStatic={true} />
                  </div>
                </>
              ) : null}

              <div className={styles.innerSvg}>
                <LazyLoadImage
                  src='/HOMEPAGE.gif'
                  alt='homepage-svg'
                  className={styles.gif}
                />
              </div>
            </div>
          </div>

          <div className={styles.blueCards}>
            <div className={styles.imgWrapper}>
              <LazyLoadImage className={styles.blueImg} src={img1} />
            </div>
            <div className={styles.imgWrapper}>
              <LazyLoadImage className={styles.blueImg} src={img2} />
            </div>
            <div className={styles.imgWrapper}>
              <LazyLoadImage className={styles.blueImg} src={img3} />
            </div>
          </div>
          <div className={styles.mytestHeader1}>
            <p className={styles.feesText}>Popular 401k and 403b Searches</p>
          </div>
          <div className={styles.row12}>
            {empLogos.map((item, key) => {
              return (
                <>
                  <img
                    onClick={async () => {
                      await dispatch(
                        fetch_allocations(
                          {
                            ein: item.ein,
                            name: item.name.split(' (EIN')[0]
                          },
                          true
                        )
                      );
                      history.push('/employer');
                    }}
                    className={styles.empImg}
                    src={item.amazon}
                    alt=''
                  ></img>
                </>
              );
            })}
          </div>
          <div className={styles.editA}>
            <div className={styles.mytestHeader}>
              <p className={styles.feesText}>Average Fees saved</p>
            </div>
            <div className={styles.feesContent}>
              <div className={styles.msgFees}>
                <div className={styles.feesHeader}>
                  <div className={styles.imgfees}>
                    <img src='/fees.svg' className={styles.feeImage} alt='' />
                  </div>
                  <div className={styles.amountCont}>
                    <div
                      className={styles.amount}
                      style={{ color: DRAK_GREEN_PLOOT }}
                    >
                      {amount.slice(0, -2)}
                      <span
                        className={styles.afterDecimal}
                        style={{ color: DRAK_GREEN_PLOOT }}
                      >
                        {amount.slice(-2)}
                      </span>
                    </div>
                    <div
                      className={styles.feesSavedText}
                      style={{ color: TEXT_BLACK_2 }}
                    >
                      Fees Saved
                    </div>
                  </div>
                </div>
                <div
                  className={styles.msgContent}
                  style={{ color: TEXT_BLACK_1 }}
                >
                  Expected fees Plootus can help you save over 30 years. The
                  amount is based on 0.70% , average fees paid by customers and
                  total fees a user will pay if they invest in Plootus
                  recommended investments.
                  <br />
                  <br />
                  <div style={{ display: 'flex' }}>
                    <MainSignupScreen
                      fromFees={true}
                      modalIsOpen={signupModal}
                      openModal={signupopenModal}
                      closeModal={signupcloseModal}
                      loginopenModal={loginopenModal}
                    />
                    <span style={{ margin: '0 5px' }}>or</span>
                    <LogIn
                      fromFees={true}
                      modalIsOpen={loginModal}
                      openModal={loginopenModal}
                      closeModal={logincloseModal}
                      signupopenModal={signupopenModal}
                      idx={1}
                    />{' '}
                    <span style={{ margin: '0 0 0 5px' }}>
                      to see the amount of fees saved for your 401k plan
                    </span>
                  </div>
                </div>
              </div>
              <div className={styles.feesGraph}>
                <FeesGraph coming={false} strategy={3} fromStatic={true} />
              </div>
            </div>
          </div>
          <div className={styles.works}>
            <div className={styles.worksText}>
              <WorksText />
            </div>
            <div className={styles.worksImage}>
              <MobileCarousel />
            </div>
          </div>
          <div className={styles.testimonials}>
            <div className={styles.testHeader}>Client Testimonials</div>
            <MyCarousel />
          </div>
          <div className={styles.blog}>
            <Blogs />
          </div>
        </>
      ) : (
        <>
          <div className={styles.main1}>
            <div className={styles.text1}>
              {width > 700 ? (
                <>
                  <div className={styles.searchParent1}>
                    <SearchBox401k {...prop} fromStatic={true} />
                  </div>
                  <div style={{ marginTop: '30%' }} />
                </>
              ) : null}
            </div>

            <div className={styles.row21}>
              <h5 className={styles.spe}>Popular 401k and 403b Searches</h5>
              {empLogos.map((item, key) => {
                return (
                  <>
                    <img
                      onClick={async () => {
                        await dispatch(
                          fetch_allocations(
                            {
                              ein: item.ein,
                              name: item.name.split(' (EIN')[0]
                            },
                            true
                          )
                        );
                        history.push('/employer');
                      }}
                      className={styles.empImg}
                      src={item.amazon}
                      alt=''
                    ></img>
                  </>
                );
              })}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default HomePage;
