import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Image1 from "../../Assets/carousel1.svg";
import Image2 from "../../Assets/carousel2.svg";
import Image3 from "../../Assets/carousel3.svg";
import styles from "./Security.module.css";
import { LazyLoadImage } from "react-lazy-load-image-component";

const Security = () => {
  const [carouselNumber, setCarouselNumber] = useState(1);
  const [prevState, setPrevState] = useState(2);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Multi-Point Security Protects Your Account | Plootus</title>
        <meta
          name="description"
          content="Safeguarding and Employing industry-leading standards to constantly enhance our services and provide the best protection available."
        />
      </Helmet>
      <div className={styles.gapper} />
      <div>
        <div className={styles.main}>
          <div className={styles.textContent}>
            <h1 className={styles.algo}>Security Is Our First Priority</h1>
            <p className={styles.algop}>
              Bank level security.
              <br />
              Data in transit is encrypted using 256-bit SSL encryption method.
            </p>
          </div>
          <div className={styles.imageContent}>
            <LazyLoadImage
              src="/security.svg"
              alt="Plootus Is all secured With Best 256 bit SSL Encryption"
              className={styles.img}
            />
          </div>
        </div>
        <div className={styles.carousel}>
          <h2 className={styles.heading}>The 3 Security Pillar</h2>
          <div className={styles.carouselInner}>
            {carouselNumber === 1 ? (
              <div className={styles.carouselItem}>
                <div
                  className={
                    prevState > carouselNumber ? styles.item : styles.back
                  }
                >
                  <div className={styles.itemImage}>
                    {" "}
                    <img
                      src={Image1}
                      className={styles.img1}
                      alt="slideImage"
                    />
                  </div>

                  <div className={styles.itemContent}>
                    <div className={styles.text}>
                      <h5>Pillar 1</h5>
                      <h6>Android/Apple App</h6>
                      <p className={styles.starter}>
                        Minimum personal data requested from users.
                      </p>
                      <p className={styles.regular}>
                        No Social Security Numbers or complete address
                        requested.
                      </p>
                      <p className={styles.regular}>
                        No login or password stored for any links to financial
                        institutions.
                      </p>
                      <p className={styles.regular}>
                        Data secured on Amazon Web Services (AWS) Cloud.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ) : carouselNumber === 2 ? (
              <div className={styles.carouselItem}>
                <div
                  className={
                    prevState > carouselNumber ? styles.item : styles.back
                  }
                >
                  <div className={styles.itemImage}>
                    {" "}
                    <img
                      src={Image2}
                      className={styles.img2}
                      alt="slideImage"
                    />
                  </div>

                  <div className={styles.itemContent}>
                    <div className={styles.text}>
                      <h5>Pillar 2</h5>
                      <h6>Link Accounts</h6>
                      <p className={styles.starter}>
                        Users directly login with partner aggregator who are
                        connected to the financial institutions platform.
                      </p>
                      <p className={styles.regular}>
                        Bank level security: 256 bit SSL encryption.
                      </p>
                      <p className={styles.regular}>
                        ISO 27002 and SOC 2 standards.
                      </p>
                      <p className={styles.regular}>
                        Strong internal controls, background checks, audits.
                      </p>
                      <p className={styles.regular}>
                        Follow PCI DSS, FCRA/State laws.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ) : carouselNumber === 3 ? (
              <div className={styles.carouselItem}>
                <div
                  className={
                    prevState > carouselNumber ? styles.item : styles.back
                  }
                >
                  <div className={styles.itemImage}>
                    {" "}
                    <img
                      src={Image3}
                      className={styles.img3}
                      alt="slideImage"
                    />
                  </div>

                  <div className={styles.itemContent}>
                    <div className={styles.text}>
                      <h5>Pillar 3</h5>
                      <h6>Retirement Account</h6>
                      <p className={styles.starter}>
                        Provide investment recommendations.
                      </p>
                      <p className={styles.regular}>
                        Do not make any allocation or fund changes on user's
                        behalf.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
          <div className={styles.carouselIndicators}>
            <div
              className={styles.carouselBtn}
              onClick={() => {
                setCarouselNumber((prev) => {
                  setPrevState(prev);
                  setCarouselNumber(1);
                });
              }}
            >
              {carouselNumber === 1 ? (
                <div className={styles.lineColored}></div>
              ) : (
                <div className={styles.line}></div>
              )}
            </div>

            <div
              className={styles.carouselBtn}
              onClick={() => {
                setCarouselNumber((prev) => {
                  setPrevState(prev);
                  setCarouselNumber(2);
                });
              }}
            >
              {carouselNumber === 2 ? (
                <div className={styles.lineColored}></div>
              ) : (
                <div className={styles.line}></div>
              )}
            </div>
            <div
              className={styles.carouselBtn}
              onClick={() => {
                setCarouselNumber((prev) => {
                  setPrevState(prev);
                  setCarouselNumber(3);
                });
              }}
            >
              {carouselNumber === 3 ? (
                <div className={styles.lineColored}></div>
              ) : (
                <div className={styles.line}></div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Security;
