import React from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import AdminRoute from "../AuthPages/AdminRoute";
import Admin from '../../components/AdminExcel/Admin'
import AdminDashboard from "./AdminDashboard";
import EmailUsers from "./EmailUsers";
import LoginAdmin from "./Login";

function AdminPages(props) {
  return (
    <div>
      <Switch>
        <AdminRoute
          path="/admin/dashboard"
          Component={() => <AdminDashboard {...props} />}
        />
        <AdminRoute
          path="/admin/email-users"
          Component={() => <EmailUsers {...props} />}
        />
        <AdminRoute
        path="/admin/excel-upload"
        Component={() => <Admin/>}
        />
        <Route
          path="/admin"
          render={(props) =>
            localStorage.getItem("admin_token") ? (
              <Redirect to="/admin/email-users" />
            ) : (
              <LoginAdmin {...props} />
            )
          }
        />
       
      </Switch>
    </div>
  );
}

export default AdminPages;
