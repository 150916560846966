import { CircularProgress } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import {
  BLUE_PLOOT,
  DANGER_RED,
  ERROR_OCCURED,
  facebook_login,
  GENERAL_GRAPH,
  getEditAssumptionsInitialState,
  getUser,
  get_clients,
  google_login,
  login_user,
  sendAlert,
  SEND_ALERT,
  TEXT_BLACK,
  tokenSelector,
  WHITE,
  resendActivationEmail,
  RESET_GENERAL
} from '@plootus/common';
import React, { useEffect, useRef, useState } from 'react';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import GoogleLogin from 'react-google-login';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import fb from '../../Assets/fb.png';
import google from '../../Assets/google.jpg';
import useWindowDimensions from '../../Reusables/VisualAndAccord/Hooks/useWindowDimensions';
import InputText from '../../Utils/InputText/InputText';
import PasswordInput from '../../Utils/PasswordInput/PasswordInput';
import ForgotFirstScreen from '../ForgotPasswordNew/ForgotFirstScreen';
import styles from './InputCard.module.css';

const theme = createMuiTheme({
  palette: {
    primary: { main: BLUE_PLOOT } // Purple and green play nicely together.
  },
  typography: { useNextVariants: true }
});

const InputCard = ({
  closeModal,
  signupopenModal,
  loading,
  setLoading,
  index,
  loginStyle
}) => {
  const [email, setEmail] = useState({
    value: localStorage.getItem('email') ? localStorage.getItem('email') : '',
    err: { err: false, value: '' },
    typed: false
  });
  const [password, setPassword] = useState(
    localStorage.getItem('password') ? localStorage.getItem('password') : ''
  );
  const [passwordError, setpasswordError] = useState(null);
  const { token } = useSelector(tokenSelector);
  const [route, setRoute] = useState(false);
  const [addRoute, setAddRoute] = useState(false);
  const [remember, setRemember] = useState(false);
  const { height, width } = useWindowDimensions();
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      if (token) {
        dispatch({
          type: RESET_GENERAL
        });
        const userData = await dispatch(getUser());
        await localStorage.setItem('jwt_token', token);
        if (userData.userType === 'advisor') {
          // type is advisor
          const clients = await dispatch(get_clients());
          setAddRoute(true);
        } else {
          dispatch({
            type: GENERAL_GRAPH,
            payload: getEditAssumptionsInitialState(token, userData)
          });

          setRoute(true);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const submitted = useRef(false);
  const handleEmail = (value) => {
    let newFields;

    if (submitted.current) {
      const result = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value);

      newFields = {
        value,
        typed: submitted.current || false,
        err: {
          err: !result,
          value: !result ? 'Enter valid email address' : ''
        }
      };
    } else {
      newFields = { ...email, value: value };
    }
    setEmail(newFields);
  };

  const submit = async () => {
    try {
      setLoading(true);
      const response = await dispatch(
        login_user(email.value, password, index === 1 ? 'primary' : 'advisor')
      );
      if (response === ERROR_OCCURED || response === 401) {
        dispatch({
          type: SEND_ALERT,
          payload: {
            title: 'Error',
            textContent: 'Incorrect email or password'
          }
        });
      } else if (response === 403) {
        dispatch(
          sendAlert({
            title: 'Verify your email',
            textContent:
              'Link for account activation has been sent to your email. Please check your Promotions or Spam folder.',
            buttonLeftText: 'Resend',
            buttonLeftTextStyle: { color: BLUE_PLOOT },
            buttonLeftContStyle: {
              backgroundColor: WHITE,
              borderWidth: 1,
              borderColor: BLUE_PLOOT
            },
            buttonLeftPress: () => {
              dispatch(resendActivationEmail(email.value));
            }
          })
        );
      }

      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleClick = (e) => {
    e.preventDefault();
    submitted.current = true;
    const result = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(
      email.value
    );
    let newFields;
    newFields = {
      value: email.value,
      typed: submitted.current || false,
      err: {
        err: !result,
        value: !result ? 'Enter valid email address' : ''
      }
    };
    setEmail(newFields);
    if (!email.err.err && password.length) {
      submit();
      if (remember) {
        localStorage.setItem('email', email.value);
        localStorage.setItem('password', password);
        localStorage.setItem('rememberStorage', remember);
      } else {
        localStorage.removeItem('email');
        localStorage.removeItem('password');
        localStorage.removeItem('rememberStorage');
      }
    } else if (!password.length) {
      setpasswordError('Password is empty');
    }
  };

  const handlePassword = (value) => {
    if (!value.length) {
      setpasswordError('Password is empty');
    } else {
      setpasswordError('');
    }
    setPassword(value);
  };

  const handleRemember = (e) => {
    setRemember(e.target.checked);
  };

  const responseGoogle = async (response) => {
    setLoading(true);
    await dispatch(google_login(response.tokenId));
    setLoading(false);
  };

  const responseFacebook = async (response) => {
    setLoading(true);
    await dispatch(facebook_login(response.accessToken));
    setLoading(false);
  };

  if (addRoute) {
    return <Redirect to='/auth/adash' />;
  }

  if (route) {
    return <Redirect to='/auth/dashboard' />;
  }

  return (
    <div className={styles.container}>
      <form onSubmit={handleClick}>
        <div className={styles.emailContainer}>
          <InputText
            title='Email'
            config={{
              onChange: (e) => handleEmail(e.target.value),
              value: email.value
            }}
            containerStyle={{ width: '90%', marginBottom: '0px' }}
            textInputStyle={{
              height: width > 576 ? '50px' : '40px',
              borderRadius: '10px'
            }}
            titleStyle={{
              paddingLeft: '10px',
              paddingBottom: '5px',
              paddingTop: '0px',
              fontSize: width > 576 ? '18px' : '14px',
              color: TEXT_BLACK
            }}
            err={email?.err}
            typed={email?.typed}
          />
        </div>
        <div className={styles.passwordContainer}>
          <PasswordInput
            textInputStyle={{
              borderRadius: '10px',
              height: width > 576 ? '50px' : '40px'
            }}
            msg=' '
            title='Password'
            config={{
              onChangeText: (value) => handlePassword(value),
              value: password,
              maxLength: 32
            }}
            msg={passwordError}
            msgStyle={{ color: DANGER_RED, fontSize: '11.5px' }}
            titleStyle={{
              fontSize: width > 576 ? '18px' : '14px',
              color: TEXT_BLACK
            }}
          />
        </div>
        <div className={styles.forgotContainer}>
          <div className={styles.forgotWrapper}>
            <div className={styles.forgot}>
              <ForgotFirstScreen />
            </div>
            <div className={styles.remember}>
              <Checkbox
                defaultChecked={
                  localStorage.getItem('rememberStorage') === 'true'
                    ? true
                    : false
                }
                color='primary'
                inputProps={{ 'aria-label': 'secondary checkbox' }}
                onChange={handleRemember}
              />
              Remember Me
            </div>
          </div>
        </div>
        <div className={styles.login} style={loginStyle}>
          {loading ? (
            <div className={styles.loader}>
              <MuiThemeProvider theme={theme}>
                <CircularProgress size={40} />
              </MuiThemeProvider>
            </div>
          ) : (
            <button
              type='submit'
              onClick={handleClick}
              className={styles.loginButton}
            >
              Login
            </button>
          )}
        </div>
      </form>
      {index !== 2 ? (
        <>
          <div className={styles.newUser}>
            New User ?{' '}
            <span style={{ paddingLeft: '5px' }}>
              <div
                href='#'
                className={styles.signupLink}
                onClick={() => {
                  closeModal();
                  signupopenModal();
                }}
              >
                {' '}
                Create an account
              </div>
            </span>
          </div>
          <div className={styles.connectWith}>
            <p className={styles.connectText}>
              <span
                style={{
                  backgroundColor: 'white',
                  paddingLeft: '5px',
                  paddingRight: '5px'
                }}
              >
                or connect with
              </span>
            </p>
          </div>
          <div className={styles.oAuth}>
            <div className={styles.fb}>
              <FacebookLogin
                appId='465677784149006'
                // autoLoad
                callback={responseFacebook}
                render={(renderProps) => (
                  <img
                    onClick={() => (loading ? null : renderProps.onClick())}
                    src={fb}
                    style={{ height: '50px', width: '50px', cursor: 'pointer' }}
                  />
                )}
              />
            </div>
            <div className={styles.google}>
              <GoogleLogin
                scope='email'
                clientId='489489080349-shsamdnahde9q73s76e7b71mrjm9snvc.apps.googleusercontent.com'
                render={(renderProps) => (
                  <img
                    onClick={() => (loading ? null : renderProps.onClick())}
                    //disabled={renderProps.disabled}
                    src={google}
                    style={{ height: '50px', width: '50px', cursor: 'pointer' }}
                  />
                )}
                buttonText='Login'
                onSuccess={responseGoogle}
                onFailure={responseGoogle}
                cookiePolicy={'single_host_origin'}
              />
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default InputCard;
