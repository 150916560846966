import React from "react";
import img1 from "../../../Assets/home-images/img1.jpeg";
import img2 from "../../../Assets/home-images/img2.jpeg";
import styles from "./MobileCarousel.module.css";
import { Carousel } from "react-responsive-carousel";
import { LazyLoadImage } from "react-lazy-load-image-component";
const MobileCarousel = () => {
  return (
    <div className={styles.carouselBackground}>
      <Carousel
        autoPlay
        infiniteLoop
        showThumbs={false}
        showStatus={false}
        className={styles.car}
      >
        <div className={styles.carouselItem}>
          <LazyLoadImage alt="" src={img2} className={styles.carouselImage} />
        </div>
        <div className={styles.carouselItem}>
          <LazyLoadImage alt="" src={img1} className={styles.carouselImage} />
        </div>
      </Carousel>
    </div>
  );
};

export default MobileCarousel;
