import React, { useEffect } from "react";
import styles from "./TermsandConditionAuth.module.css";

const TermsAndConditionAuth = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className={styles.wrapper}>
      <section class="container mb-5">
        <h2
          className="mt-5 mb-4 philoH2 text-center"
          style={{ marginTop: "10vh" }}
        >
          Terms and Conditions ("Terms")
        </h2>
        <p>
          Please read the following Terms and Conditions (the "Terms and
          Conditions") carefully before using the Plootus website or the Plootus
          mobile application (collectively referred to as the "Services"), which
          are owned and operated by Analyze Future LLC and sometimes referred as
          "us" or, "we". "You/you", "your" or "client/Client" refers to all
          visitors, users and others who access or use the Services. Your access
          to and use of the Services is conditioned on your acceptance of and
          compliance with the Terms and Conditions. The Terms and Conditions
          apply to all visitors, users and others who access or use the
          Services. By using the Services, you hereby represent and warrant that
          you accept, agree and bound by the Terms and Conditions. You may not
          use any of the Services and you may not accept the Terms and
          Conditions unless (i) you are legally authorized to accept and be
          bound by the Terms and Conditions and you are 18 years of age or in
          any event are of legal age to enter into a binding contract with
          Plootus.
        </p>

        <p>
          By accessing or using the Services, you agree to be bound by the Terms
          and Conditions. If you disagree or decline to be bound by any Terms
          and Conditions, you may not access the Services.
        </p>
        <h3>Services Scope and Limitations</h3>
        <p>
          Plootus through Plootus application or/and website ("App" & "Website")
          provides the online services to assist its clients in making decisions
          about their finances. The App & Website provides information to you
          about investment allocation and ongoing portfolio rebalancing
          recommendation based on your current asset balance, contribution
          amount, future estimated expenses, investment strategy, planned
          retirement data and other factors. Plootus analyzes these and /or
          other factors to assist you in projecting how much you will need to
          retire. The Plootus App and Website help you determine how to make
          changes to your retirement contribution amount, investment strategy,
          retirement date or future expenses to achieve or get closer to your
          retirement goals. The Plootus app and website assist in estimating
          future expenses in the retirement period based on the information
          retrieved from your bank accounts, credit cards, mortgage, utilities,
          education plans, brokerage, retirement and similar accounts capturing
          income, expenses, assets, liabilities, net worth of the Client
          (collectively known as "Accounts"), and information provided by Client
          through the App & Website. Plootus also provides its Client a
          consolidated view of Client's income & expenses, assets & liabilities,
          net worth for the financial institutions linked by the Client to the
          extent provided by unaffiliated Third-Party Aggregator (s) (facilitate
          information retrieval from financial institutions such as banks,
          credit cards, investment accounts etc.). You will be using Plootus'
          Services to provide insights to give you greater insights and assist
          you in making your life decisions which affects your finances. The
          utility of the Plootus Services is limited by the information you
          provide or that the Services retrieve from Third Party Aggregator/s or
          any other third party. Plootus shall not be responsible for any
          incomplete or inaccurate information provided by Client or retrieved
          from Third Party Aggregator or any other third party and you
          acknowledge that no liability shall accrue to Plootus in case any
          losses are suffered by you due to Plootus' Services that were provided
          based on such incomplete or inaccurate information. You agree to
          promptly update information pertaining you and your finances on the
          Plootus App and Website. This includes changes in your financial
          situation, strategy investment restrictions, new accounts and any
          other relevant factors.
        </p>

        <p>
          Plootus will not review the contents or terms of use of any third
          party aggregators or of your financial institutions including, but not
          limited to, the accuracy, validity, legality, copyright compliance, or
          decency of the content contained on said sites. You acknowledge that
          Plootus does not pre-screen content, but that Plootus will have the
          right (but not the obligation) in its sole discretion to refuse, edit,
          move or remove any content that may be available on Plootus App &
          Website.
        </p>

        <p>
          Plootus shall not be responsible for, and does not recommend, endorse
          or validate in any way, the information, products and services on or
          available through Third Party Aggregator or your financial
          Institutions or any issues you may experience which pertains to your
          access to or use of your accounts at various financial institutions
          via Plootus App & Website or otherwise. The Plootus Services may
          contain links to third-party web sites or services that are not owned,
          operated or controlled by Plootus and/ or Analyze Future LLC. Plootus
          has no control over, and assumes no responsibility for, the content,
          privacy policies, or practices of any third-party web sites or
          services. You further acknowledge and agree that Plootus shall not be
          responsible or liable, directly or indirectly, for any damage or loss
          caused by or alleged to be caused by or in connection with use of or
          reliance on any such content, goods or services available on or
          through any such web sites or services.
        </p>

        <p className="mb-3">
          The information and content provided by or through Plootus app and or
          website is for informational purposes only, and is not intended to
          substitute for professional investment, legal or accounting advice.
          You must engage professional financial and legal advisors to assist
          you in using and implementing information obtained from the Plootus
          App and or the website
        </p>

        <h5 className="font-weight-bold">Limitations:</h5>
        <p className="mb-5">
          Plootus, does not guarantee, warrant or represent that your use of
          Plootus Services will be free or errors or interruption. You hereby
          agree Plootus may periodically remove its Services for indefinite
          period of time or remove the services without notice to you. Plootus
          cannot anticipate any technical or other issues with our App &
          Website, Third party Aggregators, your financial institutions or the
          cloud platform Plootus uses. These technical or other issues may
          result in not saving of personalized assumptions or other data, user
          settings, etc. These issues may also impact the accuracy, timelines,
          transactions/assets/ liabilities categorization and other information
          provided through the App & Website. Plootus tries to maintain timely
          information concerning your latest transactions and balances. However,
          your financial institution may have more timely and accurate
          information compared to the Plootus App and Website.
        </p>

        <p className="mb-3">
          YOU EXPRESSLY AGREE THAT YOUR USE OF, OR INABILITY TO USE OF PLOOTUS
          SERVICES IS DONE AT YOUR SOLE RISK. THE SERVICE PROVIDED BY PLOOTUS IS
          ON "AS IS" AND "AS AVAILABLE BASIS" FOR YOUR USE WITHOUT WARRANTIES OF
          ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING ALL IMPLIED WARRANTIES
          OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND
          NONINFRINGEMENT. BECAUSE SOME JURISDICTIONS DO NOT ALLOW FOR THE
          EXCLUSION OF IMPLIED WARRANTIES, THE ABOVE EXCLUSION OF IMPLIED
          WARRANTIES MAY NOT APPLY TO YOU.
        </p>

        <p className="mb-3">
          Use of Plootus Services is discretionary. Plootus does not and will
          not accept custody of your assets. At all times your assets shall be
          held by an independent custodian ("Custodian") of Client's own
          choosing. The Plootus Services are not a substitute for your own
          informed judgments. You acknowledge that you are responsible for
          making all of your own investment decisions. The Services are intended
          to help you make informed choices. You are required to make any and
          all necessary changes as you deem fit.
        </p>
        <h5 className="font-weight-bold">App & Website Registration</h5>

        <p className="mb-4">
          You must first register through the App and Website to gain access to
          Plootus services. We may provide access through mobile, tablets,
          laptop or desktop or any electronic device capable of connecting to
          the internet and with computing abilities. You are required to create
          your profile and link your banks, credit cards, investments, IRA/ESRA
          and other accounts to facilitate complete profile creation.
        </p>

        <ul>
          <li>
            <h5 className="font-weight-bold">Fees for the Service</h5>
            <p>
              The Plootus Services are provided free of charge. Plootus reserves
              the right to change its fee structure at its sole discretion at
              any time. No change in fees charged to a Plootus client shall be
              effective without prior email notification sent at least thirty
              (30) days in advance.
            </p>
          </li>

          <li>
            <h5 className="font-weight-bold">Limited Power of Attorney</h5>
            <p>
              As a condition of using Plootus' Services, you hereby authorize us
              to access your linked banks, credit cards, brokerage, investments
              and other accounts and retrieve information from the Third-Party
              Aggregators or other third parties. Plootus may use and store the
              data, information and any content in accordance with this
              Agreement and our Privacy Statement. While accessing the
              third-party website, Plootus will act as an agent of the Client
              and not the third party. You represent that you are entitled to
              provide information or access to information to Plootus for the
              purpose of using Plootus' Services without any obligation on
              Plootus to pay any fees or be subject to any restrictions or
              limitations. Plootus will make all reasonable efforts to secure
              data and avoid any data breach.
            </p>
          </li>

          <li>
            <h5 className="font-weight-bold">
              Investment Risks Acknowledgement
            </h5>
            <p>
              You understand that you may lose money and Plootus does not
              guarantee the future performance of your IRA/ESRA investments. You
              understand that investment allocation recommendations made by
              Plootus may not be similar to the investment allocation
              recommended by Client or another investment advisor under the
              circumstances. The investments are subject to various market,
              currency, economic, political, geopolitical, acts of terrorism,
              and business risks. You also understand that past performance is
              no guarantee of future results. The investment value of IRA/ESRA
              assets will fluctuate according to market conditions. You may
              contact your custodian anytime to change your asset allocation in
              your IRA/ESRA.
            </p>
          </li>

          <li>
            <h5 className="font-weight-bold">Legal Authority</h5>
            <p>
              By using the Plootus App and or Website you represent that you are
              authorized to enter into an Agreement with Plootus. Client's
              death, disability or incompetence shall not terminate this
              Agreement. However, your authorized representative may terminate
              this Agreement by giving proper notice of same to Plootus. In case
              of joint account, any joint account holder agreeing to the Terms
              made herein are agreed on behalf of all joint account holders and
              further agree that each joint account holder (a) is a Client; (b)
              has the requisite authority to act on behalf of the joint account
              and Plootus will accept such instructions relating to the Account
              from any one joint account holder; (c) is jointly and severally
              liable per the terms of this Agreement; and (d) that in the case
              of death of any of the joint account holders, all right and
              interest in the Account shall vest in the surviving account
              holder(s) under the same terms and conditions of this Agreement.
              The surviving account holder(s) shall promptly provide Plootus
              with written notice thereof and provide any documentation
              reasonably requested by Plootus in its ongoing management of the
              Account.
            </p>
          </li>

          <li>
            <h5 className="font-weight-bold">Termination</h5>
            <p>
              You may terminate your account with Plootus at any time by
              accessing your profile on the Plootus App and or Website and
              following the instructions to cancel your account with Plootus.
              Plootus may terminate this Agreement at its sole discretion
              anytime without any notice to you in the following cases: (a) You
              breach or intend to breach the Terms and Conditions; (b) You fail
              to pay the requisite fees for the Services; (c) Plootus is
              required to do under any law, rule or regulation; (d) You misuse
              the Services as determined by Plootus at its sole discretion; (e)
              Plootus relies on data or services from third parties or another
              business relationship between Plootus and third party, and such
              relationship changes or terminates in such a way that it affects
              the Plootus ability to continue to provide its Services,; (f)
              continuing to provide the Services creates significant financial,
              operational or technical burdens for Plootus as determined by
              Plootus in its sole discretion; (g) continuing to provide the
              Services create Security risk for Plootus or its clients as
              determined by Plootus in its sole discretion. Termination of this
              Agreement will not affect (a) the validity of any action
              previously taken by Plootus under this Agreement; (b) liabilities
              or obligations from transactions initiated before termination of
              this Agreement; or (c) your obligation to pay fees to Plootus that
              have already been earned under this Agreement. Upon the
              termination of this Agreement, we will not have any continuing
              obligation to take any action.
            </p>
          </li>

          <li>
            <h5 className="font-weight-bold">
              Electronic Communication between Plootus and Client
            </h5>
            <p>
              You hereby agree and consent to receive all communications
              electronically in connection with your Account. Communications
              will be sent to you at your email address on record for your
              Plootus account. You shall keep your email address up to date so
              that Plootus can communicate with you. You acknowledge that all
              communications sent to you at your email address on record with
              Plootus shall be deemed to have been delivered to you personally
              regardless of whether you actually received them. All of your
              communications to Plootus shall be made via the Plootus App or
              Website. You can update your email address at any time by
              accessing your Account.
            </p>
          </li>

          <li>
            <h5 className="font-weight-bold"> Representation</h5>
            <p>
              You understand and acknowledge that Plootus is a Delaware
              corporation, with a place of business located in Orange
              Connecticut and that Plootus is licensed/registered with all of
              the appropriate regulatory jurisdictions that Plootus determines
              it has a duty to be licensed/registered. You understand and
              acknowledge that the Plootus is not engaged in the practice of law
              or accounting and that Plootus shall not provide any legal or
              accounting advice, and that Plootus shall not prepare any legal,
              tax, or accounting documents.
            </p>
          </li>

          <li>
            <h5 className="font-weight-bold">
              Non-Exclusivity of the Agreement
            </h5>
            <p>
              You understand that the Plootus assist other clients. You agree
              that the Plootus may provide assistance to other clients and that
              Plootus' recommendations for said clients may differ from the
              recommendations given to you by Plootus, or the timing or nature
              of action taken, with respect to your account.
            </p>
          </li>

          <li>
            <h5 className="font-weight-bold">Confidential Relationship</h5>
            <p>
              All information shared and recommendations provided by Plootus
              shall be treated as confidential and shall not be disclosed to any
              other person or entity, except as required by law or necessary to
              assist the Client. Plootus will hold in strict confidence all
              non-public personal and financial information that Client
              provides. Client agrees that Plootus may use and maintain your
              data according to the Plootus' Privacy Statement. Client gives
              Plootus permission to consolidate, organize or analyze Clientâ€™s
              information provided by Client or retrieved from Third Party
              Aggregator to improve the Service or for research or for offering
              promotions. Consult our Privacy Policy for further details. We may
              monitor or keep records of your communications with us in order to
              monitor the quality of our Service.
            </p>
          </li>

          <li>
            <h5 className="font-weight-bold">Amendments to this Agreement</h5>
            <p>
              Plootus may amend this Agreement at any time. The amended version
              shall be effective upon posting on Plootus' App or Website.
            </p>
          </li>

          <li>
            <h5 className="font-weight-bold">No Assignment</h5>
            <p>
              Client may not assign this Agreement without our prior consent in
              writing.
            </p>
          </li>

          <li>
            <h5 className="font-weight-bold">Governing Law</h5>
            <p>
              If any provision contained in this Agreement is or becomes
              invalid, illegal, or unenforceable in whole or in part, such
              invalidity, legality, or unenforceability shall not affect the
              remaining provisions and portions of this Agreement.
            </p>
          </li>

          <li>
            <h5 className="font-weight-bold"> Severability</h5>
            <p>
              Client may not assign this Agreement without our prior consent in
              writing.
            </p>
          </li>

          <li>
            <h5 className="font-weight-bold">
              Indemnification and Limitation of Liability
            </h5>
            <p>
              The Client holds Plootus, its employees, directors, officers,
              Third-party Aggregators, consultants, agents, subadvisors,
              suppliers, any affiliate or third party (collectively "Service
              Providers") harmless from any and all loss including legal fees
              arising from your use of Plootus Services. In no event shall
              Service Providers be held liable for any indirect, special,
              incidental, consequential, or punitive damages (including, without
              limitations, those resulting from theft or loss of profits, data,
              use, goodwill, business interruption or other intangible loss)
              arising in connection with this Agreement, Client's use of the App
              or Website, the Service, or the internet (however arising,
              including negligence), whether based on warranty, contract, tort,
              or any other legal reason and whether or not Service Provider has
              been advised of the possibility of such loss or damages. The
              parties hereby waive all rights to obtain punitive damages in
              connection with any dispute arising under this Terms and
              Agreement. You further acknowledge and agree that this Agreement
              specifically incorporates the limitation of liability and
              indemnification provisions set forth in the Terms and Conditions.
              NOTWITHSTANDING ANYTHING TO THE CONTRARY IN THIS AGREEMENT,
              PLOOTUS' LIABILITY TO YOU FOR ANY CAUSE WHATEVER AND REGARDLESS OF
              THE FORM OF THE ACTION, WILL AT ALL TIMES BE LIMITED TO THE TOTAL
              FEES PAID BY YOU TO PLOOTUS IN THE TWELVE MONTHS PRIOR TO THE
              ASSERTION OF A CLAIM AGAINST PLOOTUS, ITS AGENTS OR
              REPRESENTATIVES, OR $100.00 (ONE HUNDRED UNITED STATES DOLLARS)
              WHICHEVER IS LOWER. Plootus is not undertaking any fiduciary
              status under the Employee Retirement Income Security Act of 1974
              (ERISA), as amended, or other applicable law.
            </p>
          </li>

          <li>
            <h5 className="font-weight-bold">Arbitration Agreement</h5>
            <p>
              Any controversy or claim arising out of or relating to this
              contract, or the breach thereof, shall be settled by arbitration
              administered by the American Arbitration Association in accordance
              with its Commercial Arbitration Rules and judgment on the award
              rendered by the arbitrator(s) may be entered in any court having
              jurisdiction thereof. Claims shall be heard by a single
              arbitrator. The place of arbitration shall be Orange, CT, USA. The
              arbitration shall be governed by the laws of the State of
              Connecticut. The arbitration will be based on the submission of
              documents and there shall be no in-person or oral hearing. The
              arbitrators will have no authority to award punitive or other
              damages not measured by the prevailing party's actual damages,
              except as may be required by statute. The arbitrator(s) shall not
              award consequential damages in any arbitration initiated under
              this section. Each party shall bear its own costs and expenses and
              an equal share of the arbitrators' and administrative fees of
              arbitration. Except as may be required by law, neither a party nor
              an arbitrator may disclose the existence, content, or results of
              any arbitration hereunder without the prior written consent of
              both parties. The parties agree that failure or refusal of a party
              to pay its required share of the deposits for arbitrator
              compensation or administrative charges shall constitute a waiver
              by that party to present evidence or cross-examine witness. In
              such event, the other party shall be required to present evidence
              and legal argument as the arbitrator(s) may require for the making
              of an award. Such waiver shall not allow for a default judgment
              against the non-paying party in the absence of evidence presented
              as provided for above. PLOOTUS AND CLIENT(S) EACH AGREE THAT ANY
              AND ALL DISPUTES MUST BE BROUGHT IN THE PARTIES' INDIVIDUAL
              CAPACITY AND NOT AS A PLAINTIFF OR Name MEMBER IN ANY PURPORTED
              CLASS OR REPRESENTATIVE PROCEEDING. BY ENTERING INTO THIS
              AGREEMENT AND AGREEING TO ARBITRATION, YOU AGREE THAT client and
              Plootus ARE EACH WAIVING THE RIGHT TO FILE A LAWSUIT AND THE RIGHT
              TO A TRIAL BY A JURY. IN ADDITION, CLIENT AGREE TO WAIVE THE RIGHT
              TO PARTICIPATE IN A CLASS ACTION OR LITIGATE ON A CLASS-WIDE
              BASIS. Client AGREES THAT YOU HAVE EXPRESSLY AND KNOWINGLY WAIVED
              THESE RIGHTS. To begin an arbitration proceeding, send a letter
              requesting arbitration and describing your claim to Plootus, P.O.
              Box 123, Orange, CT, 06477. Arbitration will be conducted by the
              American Arbitration Association (AAA) before a single AAA
              arbitrator under the AAA's rules, which are available at
              www.adr.org or by calling 1-800-778-7879. The decision of the
              arbitrator shall be final and not appealable, and judgment on the
              arbitration award may be entered in any court having jurisdiction
              thereof. Plootus' liability to Client for any cause whatever and
              regardless of the form of action WILL AT ALL TIMES BE LIMITED TO
              THE TOTAL FEES PAID BY YOU TO PLOOTUS IN THE TWELVE MONTHS PRIOR
              TO THE ASSERTION OF A CLAIM AGAINST PLOOTUS, ITS AGENTS OR
              REPRESENTATIVES, OR $100.00, WHICHEVER IS LOWER (ONE HUNDRED
              UNITED STATES DOLLARS).
            </p>
          </li>

          <li>
            <h5 className="font-weight-bold">
              {" "}
              Acknowledgement of Disclosure Statement
            </h5>
            <p>
              You hereby acknowledge receipt of Plootus' Privacy Notice at
              Account opening and can access anytime through the Plootus App or
              Website. You acknowledge that Plootus has express permission for
              electronic delivery of all documents relating to your account.
              This includes Plootus' Terms, any information and Privacy Policy.
              You hereby agree to notify Plootus in the event of a change in
              your email address.
            </p>
          </li>
        </ul>

        <p>
          YOU ACKNOWLEDGE THAT YOU HAVE CAREFULLY READ AND UNDERSTAND THE TERMS
          AND CONDITIONS AND THAT UPON ENTERING YOUR NAME AND CLICKING THE "I
          AGREE" BUTTON ACCEPTS THESE TERMS AND CONDITIONS. YOU ACKNOWLEDGE THAT
          YOU HAVE RESOLVED ANY QUESTIONS ABOUT THE PROVISIONS IN THIS AGREEMENT
          PRIOR TO ENTERING INTO THIS AGREEMENT. YOU UNDERSTAND THAT TYPING YOUR
          NAME IN THE ELECTRONIC SIGNATURE FIELD BELOW AND BY CLICKING THE "I
          AGREE" BUTTON IS THE LEGAL EQUIVALENT OF MANUALLY SIGNING THIS
          AGREEMENT AND THAT YOU WILL BE LEGALLY BOUND BY ITS TERMS AND
          CONDITIONS. YOU AGREE TO PERIODICALLY CHECK THE PLOOTUS APP OR WEBSITE
          FOR UPDATES TO THE TERMS AND CONDITIONS.IF YOU USE PLOOTUS MOBILE
          APPLICATION, YOU ACKNOWLEDGE THAT YOU ARE ABLE TO ACCESS THE FULL
          FUNCTIONALITY OF OUR MOBILE APP, INCLUDING ANY DISCLOSURES THAT
          PLOOOTUS MAY PROVIDE.
        </p>
      </section>
    </div>
  );
};

export default TermsAndConditionAuth;
