import React from "react";
import useEmployerSearch from "../../Reusables/VisualAndAccord/Hooks/useEmployerSearch";
import Search401kNew from "../../Utils/Search401kNew/SearchBox401kNew";
import styles from "./Employer.module.css";
import FundsOrEmployerList from "./FundsOrEmployerList";

const Employer = () => {
  const props = useEmployerSearch();

  return (
    <div className={styles.container}>
      <div className={styles.searchDiv}>
        <div className={styles.title}>401k Plan</div>
        <div className={styles.searchWrap}>
          <Search401kNew dontNull={true} {...props} />
        </div>
        {/* this container will contain the search bar */}
      </div>
      <div className={styles.mainContent}>
        {/* this div will contain the main elements */}
        {props.employer_selected !== "" && !props.data?.length && (
          <FundsOrEmployerList />
        )}
      </div>
    </div>
  );
};

export default Employer;
