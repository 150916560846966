import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './MyCarousel.css';

export default function MyCarousel() {
  return (
    <div className="carousel-wrapper mainDiv">
      <Carousel autoPlay infiniteLoop className="carro" showStatus={false}>
        <div className="innerCaro">
          An easy to use app with a smooth, user friendly UI. It allows user to
          link their account and get detailed information on income, expenses,
          asset, and liabilities. It focuses on 401K plans to increase ROI from
          investments, through AI enabled technology.
          <p className="name-head"> Deep Bhowmick</p>
        </div>
        <div className="innerCaro">
          Fantastic work . Focused on 401k. Plootus provides best investment
          options from 401k plan without paying anything to financial advisors.
          The app also allows me to link my accounts and I can see all my
          expenses income, assets and liabilities at one place.
          <p className="name-head"> Gmel Bourne</p>
        </div>
        <div className="innerCaro">
          Much easier thank employer rolls. The app calculates the future
          expenses for retirement period. It requires minimum inputs. The best
          part is it’s free right now and helps you choose the best of
          investments 403b account.
          <p className="name-head">Kyle Johson</p>
        </div>
        <div className="innerCaro">
          A dynamic, artificial intelligence based application that helps the
          user calculate their funds, pensions and allocations by syncing itself
          with the users account. A highly accessible way to plan your
          retirement with all the required information at one place.
          <p className="name-head">J B</p>
        </div>
        <div className="innerCaro">
          I have extensively used quicken (ms money in past) in last 19 years
          and have tried betterment, wealth front and others like financial
          engines but none of the other apps gives the same flexibility and
          features like plootus.
          <p className="name-head">Manish Gupta</p>
        </div>
      </Carousel>
    </div>
  );
}
