import React, { useRef, useEffect } from "react";
import { Button, Menu, Dropdown } from "antd";
import { DownOutlined } from "@ant-design/icons";

import styles from "./AdminNav.module.css";
import Logo from "../../Assets/logo.png";

function AdminNav({
  isSaved,
  handleTestEmail,
  handleEmailUsers,
  handleSaveDesign,
  handleLoadDesignModal,
  location,
}) {
  const dashboardRef = useRef(null);
  const emailRef = useRef(null);
  const excelRef = useRef(null);

  useEffect(() => {
    if (location.pathname === "/admin/dashboard") {
      dashboardRef.current.className = styles.active;
    } else if (location.pathname === "/admin/email-users") {
      emailRef.current.className = styles.active;
    }
    else if(location.pathname === "/admin/excel-upload"){
      excelRef.current.className = styles.active;
    }
  });

  const menu = () => (
    <Menu>
      <Menu.Item>
        <Button
          className={styles.button}
          type="primary"
          onClick={handleTestEmail}
        >
          Test email
        </Button>
      </Menu.Item>
      <Menu.Item>
        <Button
          className={styles.button}
          type="primary"
          onClick={handleEmailUsers}
        >
          Email Users
        </Button>
      </Menu.Item>
    </Menu>
  );

  return (
    <div className={styles.container}>
      <nav className={styles.nav}>
        <a href="/" className={styles.link}>
          <img src={Logo} className={styles.logo} alt="PLOOTUS" />
        </a>
        <a href="/admin/dashboard" className={styles.link} ref={dashboardRef}>
          Dashboard
        </a>
        <a href="/admin/email-users" className={styles.link} ref={emailRef}>
          Email Users
        </a>
        <a href="/admin/excel-upload" className={styles.link} ref={excelRef}>
          Excel Upload
        </a>
        {location.pathname === "/admin/email-users" && (
          <div className={styles.buttonDiv}>
            <Button
              className={styles.saveButton}
              type="primary"
              onClick={handleSaveDesign}
            >
              {isSaved ? "Update Design" : "Save Design"}
            </Button>
            <Button
              className={styles.saveButton}
              type="primary"
              onClick={() => handleLoadDesignModal(true)}
            >
              Load Design
            </Button>
            <Button
              className={styles.saveButton}
              type="primary"
              onClick={handleEmailUsers}
            >
              Email Users
            </Button>
            <Button
              className={styles.saveButton}
              type="primary"
              onClick={handleTestEmail}
            >
              Test email
            </Button>
            {/* <Dropdown overlay={menu}>
              <DownOutlined />
            </Dropdown> */}
          </div>
        )}
      </nav>
    </div>
  );
}

export default AdminNav;
