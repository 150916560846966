import React from "react";
import { useSelector } from "react-redux";
import { Redirect, Route } from "react-router-dom";

const PublicRoute = ({ Component, ...rest }) => {
  const user = useSelector((state) => state.userReducer);

  return (
    <Route
      {...rest}
      render={(props) =>
        localStorage.getItem("jwt_token") ? (
          <>
            {user.userData?.userType === "advisor" ? (
              <Redirect to="/auth/adash" />
            ) : (
              <Redirect to="/auth/dashboard" />
            )}
          </>
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};

export default PublicRoute;
